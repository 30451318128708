import { useEffect, useState } from 'react';
import { log } from '../utils/logger';

const useZunTokenPrice = () => {
    const [zunTokenPrice, setZunTokenPrice] = useState(0);

    useEffect(() => {
        try {
            fetch('https://api.curve.fi/api/getPools/ethereum/factory-twocrypto')
                .then(response => {
                    response.json().then(pools => {
                        const zunPool = pools.data.poolData
                            .filter((item: any) => item.address === '0x9dBcfC09E651c040EE68D6DbEB8a09F8dd0cAA77');

                        if (zunPool.length) {
                            const price = zunPool[0].coins[1].usdPrice;
                            log(`ZUN token price - ${price}`);
                            setZunTokenPrice(price);
                        }
                    })
                });
        } catch (e) {
            setZunTokenPrice(0);
        }
    }, []);

    return zunTokenPrice;
};

export default useZunTokenPrice;
