import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { BIG_ZERO } from '../utils/formatbalance';
import { useNetwork } from 'wagmi';
import { getChainClient, getZunDaoAddress, getZunStakingAddress } from '../utils/zunami';
import stakingAbi from '../actions/abi/zun/ZUNStakingRewardDistributor.json';
import daoAbi from '../actions/abi/zun//dao.json';

import useAccount from './useAccountOverride';

const useVotingPower = () => {
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const [votingPower, setVotingPower] = useState({
        votingPower: new BigNumber(BIG_ZERO),
        usedVotes: new BigNumber(BIG_ZERO),
        pastVotes: new BigNumber(BIG_ZERO),
    });
    const { address: account } = useAccount();

    useEffect(() => {
        const getPower = async () => {
            // let result: BigInt = BigInt(0);

            const startBlock = await getChainClient(chainId).readContract({
                address: getZunDaoAddress(chainId),
                abi: daoAbi,
                functionName: 'START_BLOCK',
            });

            const lastFinalizeBlock = await getChainClient(chainId).readContract({
                address: getZunDaoAddress(chainId),
                abi: daoAbi,
                functionName: 'lastFinalizeBlock',
            });

            const votingPeriod = await getChainClient(chainId).readContract({
                address: getZunDaoAddress(chainId),
                abi: daoAbi,
                functionName: 'VOTING_PERIOD',
            });

            // last block in previous period
            const borderBlock = 
                lastFinalizeBlock - ((lastFinalizeBlock - startBlock) % votingPeriod);

            const usedVotes = await getChainClient(chainId).readContract({
                address: getZunDaoAddress(chainId),
                abi: daoAbi,
                functionName: 'usedVotes',
                args: [borderBlock, account],
            });

            console.log(`[DAO contract]: usedVotes(${borderBlock}, ${account}) - ${usedVotes}`);

            const userVotes = await getChainClient(chainId).readContract({
                address: getZunStakingAddress(chainId),
                abi: stakingAbi,
                functionName: 'getPastVotes',
                args: [account, borderBlock],
            });

            console.log(`[DAO contract]: getPastVotes(${account}, ${borderBlock}) - ${userVotes}`);

            const result = {
                pastVotes: new BigNumber(userVotes.toString()),
                votingPower: new BigNumber(userVotes.toString()).minus(new BigNumber(usedVotes.toString())),
                usedVotes: new BigNumber(usedVotes.toString()),
            };

            console.log(`[DAO POWER]: votingPower: ${result.votingPower}, usedVotes: ${result.usedVotes}, pastVotes: ${result.pastVotes}`);

            setVotingPower(result);
        };

        if (!account) {
            return;
        }

        getPower();
    }, [chainId, account]);

    return votingPower;
};

export default useVotingPower;
