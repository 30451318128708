import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { BIG_ZERO, NULL_ADDRESS } from '../utils/formatbalance';
import { log } from '../utils/logger';

import { Address, erc20ABI, sepolia } from 'wagmi';
import {
    getBaseNewZunUsdAddress,
    getBaseOldZunUsdAddress,
    getChainClient,
    getCrvUsdAddress,
    getFrxEthAddress,
    getUpdateInterval,
    getWethAddress,
    getZapAddress,
    getZunEthAddress,
    getZunEthApsAddress,
    getZunUsdAddress,
    getZunUsdApsAddress,
} from '../utils/zunami';
import {
    getDaiAddress,
    getUsdcAddress,
    getUsdtAddress,
    sepUsdtAddress,
    zunEthApsSepoliaAddress,
    zunEthZapSepoliaAddress,
    zunUsdApsSepoliaAddress,
    zunUsdZapSepoliaAddress,
} from '../sushi/lib/constants';
import { base } from '../components/NetworkSelector/NetworkSelector';

export const useAllowance = (
    coinAddress: Address,
    account: Address | undefined,
    spender: Address,
    chainId: number = 1
) => {
    const [allowance, setAllowance] = useState(BIG_ZERO);


    useEffect(() => {
        if (account === NULL_ADDRESS || !account) {
            return;
        }

        const fetchAllowance = async () => {
            try {
                const val = await getCoinAllowance(coinAddress, account, spender, chainId);
                log(`${coinAddress}.allowance('${account}', '${spender}') - ${val}`);
                setAllowance(new BigNumber(val.toString()));
            } catch (e: any) {
                // fetchAllowance();
                // alert(`Can't read allowance. Wallet: ${account}, spender: ${spender}, contract: ${coinAddress}, chain: ${chainId}. Reason: ${e.message}`);
                setAllowance(BIG_ZERO);
            }
        };

        if (account) {
            fetchAllowance();
        }

        let refreshInterval = setInterval(fetchAllowance, getUpdateInterval());
        return () => clearInterval(refreshInterval);
    }, [account, chainId, coinAddress, spender]);

    return allowance;
};

export default useAllowance;

export const getCoinAllowance = (
    coinAddress: Address,
    account: Address,
    spender: Address,
    chainId: number = 1
) => {
    // log(`Coin (${coinAddress}).allowance('${account}', '${spender}')`);

    return getChainClient(chainId).readContract({
        address: coinAddress,
        abi: erc20ABI,
        functionName: 'allowance',
        args: [
            account, //owner
            spender, // spender
        ],
    });
};

export function getTokenAddressByIndex(index: number, chainId: number) {
    let result = getUsdtAddress(chainId);

    switch (index) {
        case 0:
            result = getDaiAddress(chainId);
            break;
        case 1:
            result = getUsdcAddress(chainId);
            break;
        case 2:
            result = getUsdtAddress(chainId);
            break;
        case 4:
            result = getZunUsdApsAddress(chainId);
            break;
        case 5:
            result = getZunUsdApsAddress(chainId);
            break;
    }

    return result;
}

export function getContractNickname(address: Address) {
    let result: string = address;

    switch (address) {
        case getZunUsdAddress(1):
            result = 'zunUSD';
            break;
        case getZunEthAddress(1):
            result = 'zunETH';
            break;
        case getZunUsdApsAddress(1):
            result = 'zunUSD APS';
            break;
        case getZunEthApsAddress(1):
            result = 'zunETH APS';
            break;
        case getZapAddress(1, 'UZD'):
            result = 'zunUSD ZAP';
            break;
        case getZapAddress(1, 'ZETH'):
            result = 'zunETH ZAP';
            break;
        case getZunUsdAddress(sepolia.id):
            result = 'zunUSD_SEP';
            break;
        case sepUsdtAddress:
            result = 'Base Locked Zunami USD APS LP Staking';
            break;
        case zunUsdZapSepoliaAddress:
            result = 'ZAP_zunUSD_TEST';
            break;
        case zunEthZapSepoliaAddress:
            result = 'ZAP_zunETH_TEST';
            break;
        case zunUsdApsSepoliaAddress:
            result = 'zunUSDAps_TEST';
            break;
        case zunEthApsSepoliaAddress:
            result = 'zunETHAps_TEST';
            break;
    }

    return result;
}

export const useAllowanceStables = (
    account: Address = NULL_ADDRESS,
    spender: Address,
    chainId: number = 1,
    selectedCoinIndex: number = 1,
) => {
    const [allowance, setAllowance] = useState([
        BIG_ZERO, // 0) DAI to APS
        BIG_ZERO, // 1) USDC to APS
        BIG_ZERO, // 2) USDT to APS
        BIG_ZERO, // 3) FRAX to APS
        BIG_ZERO, // 4) zunUSD to APS
        BIG_ZERO, // 5) zunETH to APS
        BIG_ZERO, // 6) apsZunUSDLP
        BIG_ZERO, // 7) apsZunETHLP
        BIG_ZERO, // 8) ZUN
        BIG_ZERO, // 9) WETH
        BIG_ZERO, // 10) FRXETH
        BIG_ZERO, // 11) CRVUSD
        BIG_ZERO, // 12) ETH
        BIG_ZERO, // BASE zunUSD (new)
        BIG_ZERO, // BASE zunUSD (old)
    ]);


    useEffect(() => {
        const fetchAllowance = async () => {
            let result = [BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO, BIG_ZERO];

            if (chainId === 1) {
                let coinAddress = NULL_ADDRESS;

                switch (selectedCoinIndex) {
                    case 0:
                        coinAddress = getDaiAddress(chainId);
                        break;
                    case 1:
                        coinAddress = getUsdcAddress(chainId);
                        break;
                    case 2:
                        coinAddress = getUsdtAddress(chainId);
                        break;
                    case 4:
                        coinAddress = getZunUsdAddress(chainId);
                        break;
                    case 5:
                        coinAddress = getZunEthAddress(chainId);
                        break;
                    case 9:
                        coinAddress = getWethAddress();
                        break;
                    case 10:
                        coinAddress = getFrxEthAddress();
                        break;
                    case 11:
                        coinAddress = getCrvUsdAddress();
                        break;
                }

                const rawAllowance = await getCoinAllowance(coinAddress, account, spender, chainId);
                result[selectedCoinIndex] = new BigNumber(rawAllowance.toString());

                // result = result.map((val: BigNumber|string) => {
                //     if (val instanceof BigNumber) {
                //         return val.toString();
                //     }
                // });

                // result = result.map((allowance: BigInt, index: number) => new BigNumber(allowance.toString()));

                // result = [
                //     await getCoinAllowance(getDaiAddress(chainId), account, spender, chainId),
                //     await getCoinAllowance(getUsdcAddress(chainId), account, spender, chainId),
                //     await getCoinAllowance(getUsdtAddress(chainId), account, spender, chainId),
                //     BigInt('0'),
                //     await getCoinAllowance(getZunUsdAddress(chainId), account, spender, chainId),
                //     await getCoinAllowance(getZunEthAddress(chainId), account, spender, chainId),
                //     BigInt('0'),
                //     BigInt('0'),
                //     BigInt('0'),
                //     await getCoinAllowance(getWethAddress(), account, spender, chainId),
                //     await getCoinAllowance(getFrxEthAddress(), account, spender, chainId),
                //     await getCoinAllowance(getCrvUsdAddress(), account, spender, chainId),
                //     BigInt('100000000000000000'),
                //     BigInt('0'),
                //     BigInt('0'),
                // ].map((allowance: BigInt, index: number) => new BigNumber(allowance.toString()));
            }

            if (chainId === base.id) {
                result = [
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    BigInt('0'),
                    await getCoinAllowance(getBaseNewZunUsdAddress(), account, spender, chainId),
                    await getCoinAllowance(getBaseOldZunUsdAddress(), account, spender, chainId),
                ].map((allowance: BigInt, index: number) => new BigNumber(allowance.toString()));
            }

            log(
                `Allowance (${getContractNickname(spender)}): 0) DAI - ${result[0].toString()}, 
                1) USDC - ${result[1].toString()}, 
                2) USDT - ${result[2].toString()}, 
                4) zunUSD - ${result[4].toString()},
                5) zunETH - ${result[5].toString()},
                9) wETH - ${result[9].toString()},
                10) frxETH - ${result[10].toString()},
                11) crvYSD - ${result[11].toString()},
                12) ETH - ${result[12].toString()},
                13) [BASE] zunUSD (new) - ${result[13].toString()},
                14) [BASE] zunUSD (old) - ${result[14].toString()},`,
            );

            setAllowance(result);
        };

        if (account) {
            fetchAllowance();
        }

        let refreshInterval = setInterval(fetchAllowance, getUpdateInterval());
        return () => clearInterval(refreshInterval);
    }, [account, spender, chainId]);

    return allowance;
};
