import { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import './CoinSwapPanel.scss';
import { getChainClient, getCrvUsdAddress, getFrxEthAddress, getStableEthZapAddress, getStableUsdZapAddress, getWethAddress, getZunEthAddress, getZunUsdAddress } from '../../utils/zunami';
import { useUserBalances } from '../../hooks/useUserBalances';
import { getDaiAddress, getUsdcAddress, getUsdtAddress } from '../../sushi/lib/constants';
import { BIG_TEN, BIG_ZERO, bigNumberToNumber, getBalanceNumber, getFullDisplayBalance, NULL_ADDRESS } from '../../utils/formatbalance';
import useAccount from '../../hooks/useAccountOverride';
import { useNetwork, useWalletClient } from 'wagmi';
import stableZapAbi from '../../actions/abi/stable-zap-2.json';
import { log } from '../../utils/logger';
import { waitForTransaction } from '@wagmi/core';
import BigNumber from 'bignumber.js';
import useFetch from 'react-fetch-hook';
import { parseSum } from '../StakingPool/StakingPool';
import { erc20ABI } from 'wagmi';
import { Preloader } from '../Preloader/Preloader';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as HintIcon } from '../../assets/info.svg';

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
});

interface CoinSwapPanelProps {
    stakingMode: string;
}

const SUPPORTED_COINS = [
    {
        title: 'DAI',
        address: getDaiAddress(1),
        icon: '/dai.svg',
        disabled: false,
        index: 0,
    },
    {
        title: 'USDC',
        address: getUsdcAddress(1),
        icon: '/usdc.svg',
        disabled: false,
        index: 1,
    },
    {
        title: 'USDT',
        address: getUsdtAddress(1),
        icon: '/usdt.svg',
        disabled: false,
        index: 2,
    },
    {
        title: 'FRAX',
        address: getFrxEthAddress(),
        icon: '/frax.svg',
        disabled: false,
        index: 3,
    },
    {
        title: 'zunUSD',
        address: getZunUsdAddress(1),
        icon: '/zunusd.svg',
        disabled: false,
        index: 4,
    },
    {
        title: 'zunETH',
        address: getZunEthAddress(1),
        icon: '/zuneth.svg',
        disabled: false,
        index: 5,
    },
    {
        title: 'ETH',
        address: NULL_ADDRESS,
        icon: '/eth.svg',
        disabled: false,
        index: 12,
    },
    {
        title: 'WETH',
        address: getWethAddress(),
        icon: '/weth.svg',
        disabled: false,
        index: 9,
    },
    {
        title: 'frxETH',
        address: getFrxEthAddress(),
        icon: '/frxeth.svg',
        disabled: false,
        index: 10,
    },
    {
        title: 'crvUSD',
        address: getCrvUsdAddress(),
        icon: '/crvusd.svg',
        disabled: false,
        index: 11,
    },
];

const SLIPPAGE_OPTIONS = [0.1, 0.5, 1];

interface CoinProps {
    coinName: string;
    onCoinSelect?: Function;
    selected?: boolean;
}

function renderCoinItem(props: CoinProps & React.HTMLProps<HTMLButtonElement>) {
    return (
        <div
            className={`d-flex gap-2 coin-item align-items-center justify-content-center ${props.className} ${
                props.coinName === 'FRAX' ? 'disabled' : ''
            } ${props.selected ? 'selected' : ''}`}
            onClick={() => {
                if (props.onCoinSelect) {
                    props.onCoinSelect(props.coinName);
                }
            }}
        >
            <img src={`${props.coinName.toLowerCase()}.svg`} alt="" />
            <div className={'coinName'}>{props.coinName}</div>
        </div>
    );
}

function getCoinBalanceIndex(name: string)
{
    let result = 0;

    switch (name) {
        case 'DAI': result = 0; break;
        case 'USDC': result = 1; break;
        case 'USDT': result = 2; break;
        case 'FRAX': result = 3; break;
        case 'zunUSD': result = 4; break;
        case 'zunETH': result = 5; break;
        case 'WETH': result = 9; break;
        case 'frxETH': result = 10; break;
        case 'ETH': result = 12; break;
    }

    return result;
}

const formatFullBalance = (name: string, value: BigNumber) => {
    let decimals = 18;

    if (name === 'USDC' || name === 'USDT') {
        decimals = 6;
    }

    return getBalanceNumber(value, decimals).toPrecision(7, 1);
};

function getTokenDecimals(coin: string) {
    let result = 18;

    switch (coin) {
        case 'DAI': result = 18; break;
        case 'zunUSD': result = 18; break;
        case 'zunETH': result = 18; break;
        case 'ETH': result = 18; break;
        case 'USDT': result = 6; break;
        case 'USDC': result = 6; break;
    }

    return result;
}

export const CoinSwapPanel = (
    props: CoinSwapPanelProps & React.HTMLProps<HTMLDivElement>
): JSX.Element => {
    const [sellValue, setSellValue] = useState<string>('');
    const [buyValue, setBuyValue] = useState<string>('');
    const [buyMax, setBuyMax] = useState(false);
    const [sellMax, setSellMax] = useState(false);
    const [buyCoinIndex, setBuyCoinIndex] = useState(0);
    const [sellCoinIndex, setSellCoinIndex] = useState(1);
    const [buyDropdownOpen, setBuyDropdownOpen] = useState<boolean>(false);
    const [sellDropdownOpen, setSellDropdownOpen] = useState<boolean>(false);
    const [mode, setMode] = useState<string>('mint');
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const { address } = useAccount();
    const { balances } = useUserBalances(address, chainId);
    const wallet = useWalletClient();
    const [pendingTx, setPendingTx] = useState(false);
    const [transactionError, setTransactionError] = useState(false);
    const [transactionId, setTransactionId] = useState<string | undefined>(undefined);
    const [transText, setTransText] = useState<string>('');
    const [slippage, setSlippage] = useState<number>(3.5);
    const target = useRef(null);
    const [showHint, setShowHint] = useState(false);

    useEffect(() => {
        setSellValue('');
        setBuyValue('');
        setBuyMax(false);
        setSellMax(false);
        setSellCoinIndex(props.stakingMode === 'zunUSD' ? 2 : 0);
        setBuyCoinIndex(0);
    }, [props.stakingMode]);

    const coinsToSell = useMemo(() => {
        let result = [];
        let coinNames: Array<string> = [];

        if (mode === 'redeem') {
            switch (props.stakingMode) {
                case 'zunUSD':
                    coinNames = ['zunUSD'];
                    break;
                case 'zunETH':
                    coinNames = ['zunETH'];
                    break;
            }
        } else {
            switch (props.stakingMode) {
                case 'zunUSD':
                    coinNames = ['DAI', 'USDC', 'USDT', ];
                    break;
                case 'zunETH':
                    coinNames = ['frxETH', 'WETH'];
                    break;
            }
        }

        result = SUPPORTED_COINS.filter(item => coinNames.indexOf(item.title) !== -1);

        return result;
    }, [props.stakingMode, mode]);

    const coinsToBuy = useMemo(() => {
        let result = [];
        let coinNames: Array<string> = [];

        if (mode === 'mint') {
            switch (props.stakingMode) {
                case 'zunUSD':
                    coinNames = ['zunUSD'];
                    break;
                case 'zunETH':
                    coinNames = ['zunETH'];
                    break;
            }
        } else {
            switch (props.stakingMode) {
                case 'zunUSD':
                    coinNames = ['DAI', 'USDT', 'USDC', ];
                    break;
                case 'zunETH':
                    coinNames = ['ETH', 'WETH'];
                    break;
            }
        }

        result = SUPPORTED_COINS.filter(item => coinNames.indexOf(item.title) !== -1);

        return result;
    }, [props.stakingMode, mode]);

    useEffect(() => {
        if (mode === 'mint') {
            if (props.stakingMode === 'zunUSD') {
                setBuyCoinIndex(coinsToBuy.findIndex(coin => coin.title === 'zunUSD'));
                setSellCoinIndex(coinsToSell.findIndex(coin => coin.title === 'USDC'));
            } else {
                setBuyCoinIndex(coinsToBuy.findIndex(coin => coin.title === 'zunETH'));
                setSellCoinIndex(coinsToSell.findIndex(coin => coin.title === 'frxETH'));
            }
        } else {
        }
    }, [props.stakingMode, mode, coinsToBuy, coinsToSell]);

    useEffect(() => {
        document.body.addEventListener('click', function(e) {
            const allowedClasses = ['coin-item', 'coinName'];

            // @ts-ignore
            if (e.target.classList.contains(allowedClasses[0]) || e.target.classList.contains(allowedClasses[1])) {
                return;
            }
            
            document.querySelectorAll('.dropdown-toggle.show, .dropdown-menu.show').forEach((toggle) => {
                toggle.classList.remove('show');
                setBuyDropdownOpen(false);
                setSellDropdownOpen(false);
            });
        });
    }, []);

    // DAI, USDC, USDT, ETH, WETH, zunETH
    const { data: coinPricesData, isLoading: coinPricesLoading } = useFetch('https://coins.llama.fi/prices/current/ethereum:0x0000000000000000000000000000000000000000,ethereum:0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2,ethereum:0xc2e660c62f72c2ad35ace6db78a616215e2f2222,ethereum:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48,ethereum:0xdac17f958d2ee523a2206206994597c13d831ec7,ethereum:0x6B175474E89094C44Da98b954EedeAC495271d0F,ethereum:0x8C0D76C9B18779665475F3E212D9Ca1Ed6A1A0e6');

    const coinPrices = useMemo(() => {
        let result: any = {
            ZUNUSD: 0,
            ZUNETH: 0,
            ETH: 0,
            WETH: 0,
            DAI: 0,
            USDC: 0,
            USDT: 0,
        };

        if (coinPricesLoading || !coinPricesData) {
            return result;
        }

        try {
            // @ts-ignore
            Object.keys(coinPricesData.coins).forEach((index) => {
                // @ts-ignore
                const coin = coinPricesData.coins[index];
                const coinIdx = coin.symbol.toUpperCase();

                if (result.hasOwnProperty(coinIdx)) {
                    result[coinIdx] = coin.price;
                }
            });
        } catch (e) {
            debugger;
        }

        return result;
    }, [coinPricesData, coinPricesLoading]);

    const [mintApproved, setMintApproved] = useState(false);

    const [allowances, setAllowances] = useState<any>({});
    const [estInProgress, setEstInProgress] = useState<boolean|NodeJS.Timer>(false);

    const zapAddress = useMemo(() => {
        return props.stakingMode === 'zunUSD' 
            ? getStableUsdZapAddress(chainId) 
            : getStableEthZapAddress(chainId)
    }, [props.stakingMode, chainId]);

    useEffect(() => {
        async function getCoinAllowance() {
            const spender = props.stakingMode === 'zunUSD' ? getStableUsdZapAddress(chainId) : getStableEthZapAddress(chainId);
            const coinAddress = coinsToSell[sellCoinIndex].address;
            const decimals = getTokenDecimals(coinsToSell[sellCoinIndex].title);

            // @ts-ignore
            if (!allowances[coinAddress]) {
                const allowance = await getChainClient(chainId).readContract({
                    address: coinAddress,
                    abi: erc20ABI,
                    functionName: 'allowance',
                    args: [
                        address, //owner
                        spender,
                    ],
                });

                // @ts-ignore
                allowances[coinAddress] = new BigNumber(allowance);
                setAllowances(allowances);
            }

            const sellSum = new BigNumber(parseSum(sellValue))
                .multipliedBy(BIG_TEN.pow(decimals));

            let allowanceEnough = false;

            if (allowances[coinAddress].toNumber() !== 0) {
                allowanceEnough = sellSum.isLessThanOrEqualTo(allowances[coinAddress]);
            }
            
            if (!sellValue) {
                // do not show Approve btn when user input is none
                allowanceEnough = true;
            }

            console.log(`Allowance ${coinsToSell[sellCoinIndex].title} (${address}, ${spender}) - ${allowances[coinAddress]}, ${allowanceEnough.toString()}`);
            setMintApproved(allowanceEnough);
        }

        if (address) {
            getCoinAllowance();
        }

    }, [props.stakingMode, address, chainId, coinsToSell, sellCoinIndex, sellValue, allowances]);

    // enable/disabled need to show Approve button
    const needToApprove = useMemo(() => {
        if (!Number(sellValue)) {
            return false;
        }

        if (mode === 'mint') {
            return !mintApproved;
        } else {
            return !mintApproved;
        }

        // if (props.stakingMode === 'zunETH') {

        // }
    }, [mode, mintApproved, sellValue]);

    const [sellPlaceholder, setSellPlaceholder] = useState<string>('0.00');

    useEffect(() => {
        if (!coinsToSell) {
            return;
        }

        const selCoin = coinsToSell[sellCoinIndex];
        const selCoinIndex = getCoinBalanceIndex(selCoin.title);
        const selCoinBalance = balances[selCoinIndex];
        console.log(`Selected coin (${selCoin.title}) balance (index ${selCoinIndex}): ${selCoinBalance}`);

        setSellPlaceholder(
            bigNumberToNumber(selCoinBalance, getTokenDecimals(selCoin.title)).toString()
        );
    }, [balances, coinsToSell, sellCoinIndex]);

    const [actionEnabled, setActionEnabled] = useState(false);

    // enabled/disabled Mint button
    useEffect(() => {
        const inputsEmpty = !sellValue || !buyValue || !Number(sellValue) || !Number(buyValue);

        if (inputsEmpty) {
            setActionEnabled(false);
            return;
        } else {
            setActionEnabled(true);
        }

        if (mode === 'mint') {
            return setActionEnabled(!needToApprove);
        } else {
            return setActionEnabled(!needToApprove);
        }
    }, [mode, needToApprove, sellValue, buyValue]);

    const swap = async () => {
        if (!wallet.data) {
            return;
        }

        try {
            let args = [
                ['0','0','0','0','0'],
                address,
            ];

            if (mode === 'mint') {
                if (props.stakingMode === 'zunUSD') {
                    const decimals = getTokenDecimals(coinsToSell[sellCoinIndex].title);
                    // @ts-ignore
                    args[0][sellCoinIndex] =
                        new BigNumber(parseSum(sellValue))
                        .multipliedBy(BIG_TEN.pow(decimals))
                        .toString()
                } else {
                    const decimals = getTokenDecimals(coinsToSell[sellCoinIndex].title);
                    // @ts-ignore
                    args[0][sellCoinIndex] =
                        new BigNumber(parseSum(sellValue))
                        .multipliedBy(BIG_TEN.pow(decimals))
                        .toString()
                }

                // setting minMount from estimateMint
                // @ts-ignore
                args[2] = estimationVal.toString();
            } else {
                const sellDecimals = getTokenDecimals(coinsToSell[sellCoinIndex].title);
                const buyDecimals = getTokenDecimals(coinsToBuy[buyCoinIndex].title);
                const balanceIndex = getCoinBalanceIndex(coinsToBuy[buyCoinIndex].title);
                const balance = balances[getCoinBalanceIndex(coinsToSell[sellCoinIndex].title)];

                const zunStableToSell = sellMax
                    ? balance
                    : new BigNumber(parseSum(sellValue)).multipliedBy(BIG_TEN.pow(sellDecimals));

                args = [
                    // @ts-ignore
                    zunStableToSell.toString(),
                    address,
                    ['0', '0', '0', '0', '0'],                    
                ];

                let redeemCoinRate = coinPrices[coinsToBuy[buyCoinIndex].title.toUpperCase()];
                const stableBN = new BigNumber(redeemCoinRate).multipliedBy(BIG_TEN.pow(buyDecimals));
                let conversionResult = zunStableToSell.dividedBy(stableBN);
                // @ts-ignore
                conversionResult = Math.floor(conversionResult.toNumber() / 10000000).toString();
                // @ts-ignore
                // args[2][balanceIndex] = conversionResult;

                // slippage
                // 3/ При редиме третьим параметром указывает изначальное введенное значение zunStable умноженное на 1 - (слипадж):

                // function redeem(
                //     uint256 zunStableAmount,
                //     ddress receiver,
                //     uint256 minAmountBased
                // ) external

                
                const slp = zunStableToSell.multipliedBy(new BigNumber(slippage / 100));
                // @ts-ignore
                args[2] = zunStableToSell.minus(
                    slp
                ).dp(0, BigNumber.ROUND_DOWN);

                log(`Redeem coin price (${coinsToBuy[buyCoinIndex].title.toUpperCase()}): ${redeemCoinRate}`);
                log(`Redeem raw input: ${buyDecimals}, final bignumber value: ${conversionResult}`);
                log(`Slippage: ${slippage}, minAmount: ${slp}`)
            }

            setPendingTx(true);

            const zapAddress = props.stakingMode === 'zunUSD' 
                ? getStableUsdZapAddress(chainId) 
                : getStableEthZapAddress(chainId);

            log(`[STABLE-ZAP] Calling ${mode}(${args}`);

            setTransText(`(${zapAddress}).${mode}${JSON.stringify(args)}`);

            const txHash = await wallet.data.writeContract({
                address: zapAddress,
                chain: chain,
                abi: stableZapAbi,
                functionName: mode,
                args: args,
            });

            waitForTransaction({ hash: txHash }).then(() => {
                setTransactionId(txHash);
                setPendingTx(false);
            });
        } catch (e: any) {
            log(`[SMART] Error while using stable ZAP: ${e.message}`);
        }

        setPendingTx(false);
    };

    const [estimationVal, setEstimationVal] = useState<BigNumber>(BIG_ZERO);

    const getEstimation = useCallback(async (value: any) => {
        if (!Number(value)) {
            return;
        }

        if (estInProgress) {
            // @ts-ignore
            clearTimeout(estInProgress);
            setEstInProgress(false);
            // console.log('TIMER CLEAR');
        }

        const args = [
            ['0','0','0','0','0']
        ];

        const timerID = setTimeout(async () => {
            const decimals = getTokenDecimals(coinsToSell[sellCoinIndex].title);
            // @ts-ignore
            args[0][sellCoinIndex] =
                new BigNumber(parseSum(value))
                .multipliedBy(BIG_TEN.pow(decimals))
                .toString();

            const estimation = await getChainClient(chainId).readContract({
                address: zapAddress,
                abi: stableZapAbi,
                functionName: 'estimateMint',
                args: args,
            });

            log(`Calling estimateMint(${args}) of ${zapAddress}, result - ${estimation}`);

            const bnValue = new BigNumber(estimation.toString());
            setEstimationVal(bnValue);
            setBuyValue(getFullDisplayBalance(bnValue));
            // console.log('TIMER SUCCESS');

            // @ts-ignore
            clearTimeout(estInProgress);
            setEstInProgress(false);
        }, 1000);

        // console.log('TIMER SET');
        setEstInProgress(timerID);
    }, [chainId, coinsToSell, estInProgress, sellCoinIndex, zapAddress]);

    console.log(coinsToBuy[buyCoinIndex], buyCoinIndex);

    return (
        <div className={`CoinSwapPanel ${props.className ? props.className : ''}`}>
            <div className="position-relative">
                <div className="gray-block from mb-2">
                    <div className="name">You send</div>
                    <div className="row w-100">
                        <div className="col-xs-12 col-lg-6">
                            <div className="input-wrapper">
                                <input
                                    inputMode={'decimal'}
                                    autoComplete={'off'}
                                    autoCorrect={'off'}
                                    pattern={'^[0-9]*[.,]?[0-9]*$'}
                                    placeholder={sellPlaceholder}
                                    min={0}
                                    minLength={1}
                                    maxLength={79}
                                    type="text"
                                    value={sellValue}
                                    className={`${estInProgress ? 'disabled' : ''}`}
                                    onChange={async(e) => {
                                        const value = e.currentTarget.value;
                                        setSellValue(value);

                                        if (mode === 'mint') {
                                            // if (props.stakingMode === 'zunUSD') {
                                            //     let result = Number(value) / coinPrices.ZUNUSD;
                                            //     setBuyValue(result.toString());
                                            // }
                                        } else {
                                            if (props.stakingMode === 'zunUSD') {
                                                let result = Number(value) * coinPrices.ZUNUSD;
                                                setBuyValue(result.toString());
                                            } else {
                                                let result = Number(value) * coinPrices.ZUNETH;
                                                const coinToBuyPrice = coinPrices[coinsToBuy[buyCoinIndex].title];

                                                result = result / coinToBuyPrice;
                                                setBuyValue(result.toString());
                                            }
                                        }

                                        setSellMax(false);
                                        setBuyMax(false);

                                        if (mode === 'mint') {
                                            await getEstimation(value);
                                        }
                                    }}
                                />
                                <button
                                    className="max"
                                    onClick={async () => {
                                        const balanceIndex =
                                            getCoinBalanceIndex(coinsToSell[sellCoinIndex].title);
                                        const coinName = coinsToSell[sellCoinIndex].title;
                                        const balance = balances[balanceIndex];
                                        const formattedValue = formatFullBalance(coinName, balance);
                                        // let redeemCoinRate = coinPrices[coinsToBuy[buyCoinIndex].title.toUpperCase()];
                                        
                                        setSellValue(formattedValue);

                                        if (mode === 'mint') {
                                            // if (props.stakingMode === 'zunUSD') {
                                            //     let result = Number(formattedValue) / coinPrices.ZUNUSD;
                                            //     // round down
                                            //     result = Math.floor(result);
                                            //     setBuyValue(result.toString());
                                            // } else {
                                            //     setBuyValue(formattedValue);
                                            // }
                                            if (mode === 'mint') {
                                                await getEstimation(formattedValue);
                                            }
                                        } else {
                                            if (props.stakingMode === 'zunUSD') {
                                                let result = Number(formattedValue) * coinPrices.ZUNUSD;
                                                setBuyValue(result.toString());
                                            } else {
                                                // let result = Number(formattedValue) * coinPrices.ZUNETH;
                                                // setBuyValue(result.toString());

                                                let result = Number(formattedValue) * coinPrices.ZUNETH;
                                                const coinToBuyPrice = coinPrices[coinsToBuy[buyCoinIndex].title];
                                                result = result / coinToBuyPrice;
                                                setBuyValue(result.toString());

                                            }
                                        }

                                        setSellMax(true);
                                    }}
                                >max</button>
                            </div>
                        </div>
                        <div className="col-xs-12 col-lg-6">
                            <div
                                className="dropdown"
                                onClick={() => {
                                    if (coinsToSell.length <= 1) {
                                        return;
                                    }

                                    setBuyDropdownOpen(!buyDropdownOpen);
                                }}
                            >
                                <button className={`mt-2 mt-lg-0 ms-0 ms-lg-2 mb-3 mb-lg-0 btn btn-secondary dropdown-toggle ${buyDropdownOpen ? 'show' : ''}`} type="button">
                                    {
                                        typeof (coinsToSell[sellCoinIndex]) !== 'undefined' &&
                                            renderCoinItem({ coinName: coinsToSell[sellCoinIndex].title })
                                    }
                                </button>
                                <ul className={`dropdown-menu ${buyDropdownOpen ? 'show' : ''}`}>
                                    {
                                        coinsToSell.map((coin, coinIndex) =>
                                            <li
                                                key={coinIndex}
                                                onClick={() => {
                                                    setSellCoinIndex(
                                                        coinsToSell.findIndex(item => item.title === coin.title)
                                                    );
                                                    setSellValue('');
                                                    setBuyValue('');
                                                }}
                                            >
                                                <div
                                                    className="dropdown-item d-flex gap-2"
                                                >
                                                    <img src={coin.icon} alt={coin.title} className="" />
                                                    {coin.title}
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="swap-btn"
                    onClick={() => {
                        setSellCoinIndex(buyCoinIndex);
                        setBuyCoinIndex(sellCoinIndex);

                        setBuyValue(sellValue);
                        setSellValue(buyValue);

                        setMode(mode === 'mint' ? 'redeem' : 'mint');
                    }}
                >
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.1999 3.55072L13.1999 3.7913C13.1999 7.64371 13.1995 11.4961 13.1989 15.3485C13.1989 15.4671 13.1999 15.5892 13.1737 15.7035C13.0633 16.1844 12.6448 16.4807 12.1466 16.4419C11.6825 16.4058 11.3211 16.0326 11.2778 15.5422C11.2713 15.4682 11.274 15.3933 11.274 15.3188C11.2739 10.6691 11.2741 6.01934 11.2748 1.36962C11.2748 1.25097 11.2769 1.13006 11.2986 1.01405C11.4385 0.267025 12.2953 -0.033989 12.8724 0.458859C12.929 0.507166 12.9817 0.560196 13.0344 0.612839C14.5475 2.12481 16.0604 3.637 17.5732 5.14941C17.6377 5.21125 17.6991 5.27636 17.7571 5.34447C17.9068 5.5282 17.9831 5.76092 17.9711 5.99762C17.9591 6.23431 17.8597 6.45815 17.6922 6.62582C17.5246 6.79348 17.3008 6.89307 17.064 6.90531C16.8273 6.91754 16.5944 6.84155 16.4104 6.69204C16.3424 6.63402 16.2773 6.57253 16.2156 6.50781C15.277 5.57022 14.3388 4.63224 13.4009 3.69387C13.3487 3.64159 13.3035 3.58235 13.255 3.52635C13.2366 3.53448 13.2182 3.5426 13.1999 3.55072Z" fill="url(#paint0_linear_1847_14080)"/>
                        <path d="M4.79003 16.0233C4.79003 15.8889 4.79003 15.8023 4.79003 15.7157C4.79003 11.8914 4.79018 8.06707 4.79047 4.24274C4.79048 4.13104 4.78957 4.01853 4.80175 3.90779C4.85378 3.43478 5.27011 3.07995 5.76085 3.08527C6.24026 3.09046 6.64459 3.44592 6.69541 3.91072C6.70432 4.0072 6.70799 4.10411 6.70639 4.20099C6.70687 8.86026 6.70647 13.5195 6.7052 18.1788C6.70514 18.3268 6.70493 18.4812 6.66533 18.6216C6.48019 19.2774 5.68775 19.5264 5.1517 19.1042C5.09397 19.057 5.03907 19.0065 4.98727 18.9528C3.44702 17.4139 1.90681 15.8749 0.366642 14.3359C0.0941085 14.0639 -0.0397235 13.7526 0.0564325 13.3656C0.218866 12.7118 0.97659 12.4229 1.52957 12.807C1.62058 12.8703 1.70115 12.9502 1.78005 13.0289C2.71449 13.961 3.64828 14.8937 4.58144 15.827C4.6371 15.8826 4.69598 15.935 4.79003 16.0233Z" fill="#CBCBCB"/>
                        <defs>
                        <linearGradient id="paint0_linear_1847_14080" x1="11.259" y1="0.217589" x2="12.8449" y2="18.1913" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FA5403"/>
                            <stop offset="1" stopColor="#FB9210"/>
                        </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="gray-block buy mt-2">
                    <div className="name">You receive</div>
                    <div className="row w-100">
                        <div className="col-xs-12 col-lg-6">
                            <div className="input-wrapper">
                                {
                                    mode === 'mint' && estInProgress && <Preloader onlyIcon={true} className="position-absolute" />
                                }
                                <input
                                    inputMode={'decimal'}
                                    autoComplete={'off'}
                                    autoCorrect={'off'}
                                    pattern={'^[0-9]*[.,]?[0-9]*$'}
                                    placeholder={'0.00'}
                                    min={0}
                                    minLength={1}
                                    maxLength={79}
                                    type="text"
                                    value={buyValue}
                                    className={`${estInProgress ? 'disabled' : ''}`}
                                    onChange={(e) => {
                                        const value = e.currentTarget.value;
                                        setBuyValue(value);

                                        if (mode === 'mint') {
                                            // if (props.stakingMode === 'zunUSD') {
                                            //     let result = Number(value) / zunPrices.zunUSD;
                                            //     setBuyValue(result.toString());
                                            // }
                                        } else {
                                            if (props.stakingMode === 'zunUSD') {
                                                let result = Number(value) / coinPrices.ZUNUSD;
                                                setSellValue(result.toString());
                                            }
                                        }

                                        setSellMax(false);
                                        setBuyMax(false);
                                    }}
                                />
                                <button
                                    className="max d-none"
                                    onClick={() => {
                                        const balanceIndex =
                                            getCoinBalanceIndex(coinsToBuy[buyCoinIndex].title);
                                        const coinName = coinsToBuy[buyCoinIndex].title;
                                        const balance = balances[balanceIndex];
                                        const formattedValue = formatFullBalance(coinName, balance);

                                        setBuyValue(formattedValue);

                                        if (mode === 'mint') {
                                            if (props.stakingMode === 'zunUSD') {
                                                let result = Number(formattedValue) / coinPrices.ZUNUSD;
                                                setSellValue(result.toString());
                                            }
                                        } else {
                                            if (props.stakingMode === 'zunUSD') {
                                                let result = Number(formattedValue) / coinPrices.ZUNUSD;
                                                setBuyValue(result.toString());
                                            }
                                        }

                                        setBuyMax(true);
                                    }}
                                >max</button>
                            </div>
                        </div>
                        <div className="col-xs-12 col-lg-6">
                            <div
                                className="dropdown"
                                onClick={() => {
                                    if (coinsToBuy.length <= 1) {
                                        return;
                                    }

                                    setSellDropdownOpen(!sellDropdownOpen);
                                }}
                            >
                                <button className={`mt-2 mt-lg-0 ms-0 ms-lg-2 mb-3 mb-lg-0 btn btn-secondary dropdown-toggle ${sellDropdownOpen ? 'show' : ''}`} type="button">
                                    {
                                        typeof (coinsToBuy[buyCoinIndex]) !== 'undefined' &&
                                            renderCoinItem({
                                                coinName: coinsToBuy[buyCoinIndex].title
                                            })
                                    }
                                </button>
                                <ul className={`dropdown-menu ${sellDropdownOpen ? 'show' : ''}`}>
                                    {
                                        coinsToBuy.map((coin, coinIndex) =>
                                            <li
                                                key={coinIndex}
                                                onClick={() => {
                                                    if (mode === 'mint') {
                                                        setSellCoinIndex(coin.index);
                                                    } else {
                                                        // setBuyCoinIndex(coin.index);
                                                        // @ts-ignore
                                                        setBuyCoinIndex(coinsToBuy.findIndex(item => item.title === coin.title));
                                                    }

                                                    setSellValue('');
                                                    setBuyValue('');
                                                }}
                                            >
                                                <div
                                                    className="dropdown-item d-flex gap-2"
                                                >
                                                    <img src={coin.icon} alt={coin.title} className="" />
                                                    {coin.title}
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col d-flex gap-2 align-items-center mt-3 mb-2">
                    {
                        needToApprove && (
                        <input
                            type="button"
                            className={`zun-button`}
                            value="Approve"
                            onClick={async () => {
                                if (!wallet.data) {
                                    return;
                                }

                                try {
                                    setPendingTx(true);
                                    const spendingSum = '100000000000000000000000';
                                    const coinAddress = coinsToSell[sellCoinIndex].address;

                                    const txHash = await wallet.data.writeContract({
                                        address: coinAddress,
                                        chain: chain,
                                        abi: erc20ABI,
                                        functionName: 'approve',
                                        args: [
                                            zapAddress,
                                            // @ts-ignore
                                            spendingSum,
                                        ],
                                    });

                                    setTimeout(() => {
                                        setTransactionId(txHash);
                                        allowances[coinAddress] = spendingSum;
                                        setAllowances(allowances);
                                        setMintApproved(true);
                                        setPendingTx(false);
                                    }, 8000);
                                } catch (e: any) {
                                    log(`[SMART] Error while approving: ${e.message}`);
                                    setPendingTx(false);
                                }
                            }}
                        />
                        )
                    }
                    {!needToApprove &&
                        <input
                            type="button"
                            className={`zun-button ${!actionEnabled || pendingTx ? 'disabled' : ''}`}
                            value="Swap"
                            onClick={swap}
                        />
                    }
                    <div ref={target} onClick={() => setShowHint(!showHint)} className="hint gap-2">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Here, you can mint and redeem zunStables. Please note that a 0.75% fee applies to all redemption transactions, while minting is fee-free.</Tooltip>}>
                            <HintIcon />
                        </OverlayTrigger>
                    </div>
                    <div className="curr-slippage  flex-grow">
                        Swap slippage: {slippage}%
                    </div>
                    <div className="d-flex gap-1 ms-auto me-0 d-none">
                        {
                            SLIPPAGE_OPTIONS.map(slpValue =>
                                <button
                                    key={slpValue}
                                    className={`zun slippage-option ${slpValue === slippage ? 'active' : ''}`}
                                    onClick={() => {
                                        setSlippage(slpValue);
                                    }}
                                >{slpValue}%</button>
                            )
                        }
                    </div>
                </div>
                <div className="col-xs-12">
                    {(pendingTx) && <Preloader />}
                </div>
                {/* {transText && 
                    <div className={`alert alert-info p-2 ${window.location.hostname !== 'localhost' ? 'd-none' : 'd-block'}`}>
                        <span className="text-small text-info">{transText}</span>
                    </div>
                }
                <div className={`chameleon-text text-small ${window.location.hostname !== 'localhost' ? 'd-none' : 'd-block'}`}>
                    {
                        Object.keys(coinPrices).map((index) => 
                            <div key={index}>{index}: ${coinPrices[index]}</div>
                        )
                    }
                </div> */}
            </div>
        </div>
    );
};
