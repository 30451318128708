import React from 'react';
import { Button } from 'react-bootstrap';
import './VerificationInfoPanel.scss';

interface VerificationInfoPanelProps {
    customTitle?: string;
}

export const VerificationInfoPanel: React.FC<VerificationInfoPanelProps & React.HTMLProps<HTMLDivElement>> = ({ className, customTitle }) => {
    return (
        <div className={`verification-info-panel ${className ?? ''}`}>
            <a
                href="https://zunamilab.gitbook.io/zunami-docs/risks-and-security/audits"
                target="_blank"
                rel="noreferrer"
            >
                <span>{customTitle ? customTitle : 'Non-upgradable | Audited & formally verified'}</span>
            </a>
            <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.803 7.95983C12.6769 7.83247 12.5026 7.76355 12.3137 7.76483C11.9257 7.76815 11.6426 8.0662 11.6405 8.47353C11.64 8.5711 11.6403 8.66868 11.6405 8.76626C11.6409 8.91244 11.6412 9.06361 11.6389 9.21139C11.634 9.52425 11.5629 9.83139 11.4276 10.1243C11.2473 10.5145 10.9421 10.8188 10.6674 11.065C10.2437 11.4448 9.7452 11.6382 9.18596 11.6399C7.69592 11.6443 6.1807 11.643 4.71537 11.6418L3.81104 11.6411C3.54877 11.6409 3.2922 11.5925 3.04847 11.4971C2.48976 11.2786 2.03457 10.8776 1.65686 10.271C1.46344 9.96041 1.365 9.60699 1.36427 9.22058C1.36073 7.34699 1.36195 5.50006 1.3638 3.78327C1.36405 3.54039 1.41059 3.29792 1.50608 3.04197C1.66483 2.61654 1.95826 2.24569 2.45756 1.83953C2.78781 1.57088 3.1615 1.41822 3.56826 1.3858C3.79495 1.36773 4.03039 1.36662 4.25808 1.36553C4.35261 1.36508 4.44711 1.36463 4.5415 1.36306C4.94787 1.35631 5.24117 1.06793 5.23892 0.677344C5.23673 0.295237 4.93833 0.00491136 4.54483 0.0020254L4.53544 0.00195245C4.27858 6.18993e-05 4.01291 -0.0019016 3.75053 0.00357817C3.35865 0.0117337 2.96566 0.0868947 2.58251 0.22698C2.06412 0.4165 1.59653 0.734223 1.11097 1.22685C0.368985 1.97963 -0.00477572 2.88024 4.60618e-05 3.90366C0.00289536 4.50466 0.00220146 5.11601 0.00150718 5.70724C0.00121503 5.97302 0.000904555 6.23879 0.000904555 6.50457L0.0014709 6.68163C0.00139795 6.88756 0.00121503 7.0935 0.00105082 7.29944C0.000484473 7.92821 -8.14966e-05 8.57841 0.00262154 9.21813C0.00464901 9.69396 0.106313 10.1653 0.304801 10.6192C0.512439 11.0939 0.838528 11.5304 1.33099 11.9928C2.03828 12.6569 2.87141 12.9959 3.80722 13.0004C4.60587 13.0042 5.41511 13.0055 6.21218 13.0055C7.2529 13.0055 8.27285 13.0032 9.2211 13.0011C9.73074 12.9999 10.2416 12.8794 10.7395 12.6427C11.1645 12.4406 11.5489 12.1432 11.9859 11.6783C12.5416 11.0871 12.8666 10.4267 12.9518 9.71546C12.9905 9.39288 12.9935 9.0658 12.9965 8.74948C12.9974 8.65931 12.9982 8.56917 12.9999 8.47918C13.0036 8.27621 12.9337 8.09177 12.803 7.95983Z"
                    fill="#686868"
                />
                <path
                    d="M4.1556 7.88544L4.1416 7.89942C4.10028 7.94055 4.05345 7.98717 4.01263 8.0423C3.84462 8.26924 3.83536 8.57057 3.98901 8.80996C4.11538 9.00693 4.33126 9.12125 4.56202 9.12125C4.60533 9.12125 4.64919 9.11722 4.69306 9.10896C4.8823 9.07329 5.00696 8.96144 5.10896 8.85939C6.95049 7.01679 8.79252 5.17469 10.6346 3.33258L10.6559 3.3112C10.6562 3.55934 10.6561 3.80746 10.6561 4.05558C10.6561 4.52556 10.6561 4.99555 10.6577 5.46552C10.6581 5.57915 10.6741 5.68116 10.7053 5.76872C10.8165 6.08086 11.1308 6.25606 11.4696 6.19488C11.7858 6.13777 12.0158 5.85689 12.0165 5.527C12.0185 4.62914 12.0178 3.81815 12.0144 3.04771C12.0139 2.93473 11.9989 2.81066 11.9698 2.67897C11.7439 1.65588 10.8865 0.967725 9.83635 0.966611C9.36568 0.966108 8.89498 0.966254 8.42431 0.966373L7.86379 0.966492C7.8236 0.966492 7.7834 0.96608 7.74318 0.96567C7.64742 0.964711 7.54842 0.963706 7.44869 0.968145C7.10879 0.983323 6.83639 1.23839 6.80097 1.57461C6.7651 1.91514 6.97721 2.22219 7.30529 2.30468C7.39464 2.32716 7.4816 2.32725 7.55834 2.32733C7.87741 2.32763 8.19645 2.3275 8.51549 2.32736C8.91509 2.32718 9.3147 2.32701 9.71427 2.32772C7.83476 4.20525 5.93929 6.10115 4.1556 7.88544Z"
                    fill="#686868"
                />
            </svg>
        </div>
    );
};
