import {
    Address,
    erc20ABI,
    useContractWrite,
    usePrepareContractWrite,
    erc721ABI,
} from 'wagmi';
import baseZunUsdAbi from '../actions/abi/base/zunusd.json';
import { log } from '../utils/logger';
import { getUsdtAddress } from '../sushi/lib/constants';
import { base } from '../components/NetworkSelector/NetworkSelector';
import { useMemo } from 'react';

const useApprove = (
    coinAddress: Address,
    spender: Address,
    amount: string,
    chainId: number | undefined
) => {
    const typedAmount: bigint = BigInt(amount);
    const abi = useMemo(() => {
        let result = coinAddress === getUsdtAddress(chainId) ? erc721ABI : erc20ABI;

        if (chainId === base.id) {
            // @ts-ignore
            result = baseZunUsdAbi;
        }

        return result;
    }, [chainId, coinAddress]);

    log(`${coinAddress}.approve(${spender}, ${typedAmount}) (chanID ${chainId})`);

    const { config } = usePrepareContractWrite({
        address: coinAddress,
        // @ts-ignore
        abi: abi,
        functionName: 'approve',
        args: [spender, typedAmount],
        chainId: chainId,
    });

    const result = useContractWrite(config);

    if (result.isLoading) {
        log(`[SMART] calling approve of ${coinAddress}. Params: ('${spender}', '${typedAmount}')`);
    }

    return result;
};

export default useApprove;
