import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { BIG_TEN, BIG_ZERO, getFullDisplayBalance } from '../utils/formatbalance';
import { Address, useNetwork } from 'wagmi';
import { getChainClient, getZunEthApsAddress } from '../utils/zunami';
import apsAbi from '../actions/abi/aps.json';
import { base } from '../components/NetworkSelector/NetworkSelector';
import { log } from '../utils/logger';

const useLpPrice = (contractAddress: Address) => {
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const [balance, setBalance] = useState(new BigNumber(BIG_ZERO));

    useEffect(() => {
        const fetchBalance = async () => {
            let result: BigInt = BigInt(0);
            // @ts-ignore
            const supportedChain = [1, base.id].indexOf(chainId) !== -1;

            // zunETH lpPrice disabled for any network except Mainnet
            if (contractAddress === getZunEthApsAddress(1) && !supportedChain) {
                return;
            }

            try {
                // @ts-ignore
                result = await getChainClient(chainId).readContract({
                    address: contractAddress,
                    abi: apsAbi,
                    functionName: 'tokenPrice',
                });

                setBalance(new BigNumber(result.toString()).dividedBy(BIG_TEN.pow(18)));
            } catch (e) {
                setBalance(BIG_ZERO);
            }
        };

        fetchBalance();

        let refreshInterval = setInterval(fetchBalance, 60000);
        return () => clearInterval(refreshInterval);
    }, [chainId, contractAddress]);

    return balance;
};

export default useLpPrice;
