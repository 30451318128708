import { Address, useAccount } from 'wagmi';

type UseAccountConfig = {
    /** Function to invoke when connected */
    onConnect?({
        address,
        connector,
        isReconnected,
    }: {
        address?: Address;
        connector?: Address;
        isReconnected: boolean;
    }): void;
    /** Function to invoke when disconnected */
    onDisconnect?(): void;
};

const useAccountOverride = (config?: UseAccountConfig) => {
    const rawResult = useAccount();
    const result = {
        ...rawResult,
    };

    const overrideAddress = new URLSearchParams(window.location.search).get('override');

    if (overrideAddress) {
        result.address = overrideAddress.toLowerCase() as Address;
    }
    // const overridedAddress: Address = '0xe9b2b067ee106a6e518fb0552f3296d22b82b32b';

    return result;

    // return result;
};

export default useAccountOverride;
