import { BigNumber } from 'bignumber.js';
import { getBalanceNumber } from '../utils/formatbalance';
import { base } from '../components/NetworkSelector/NetworkSelector';

export interface PoolInfo {
    pid: number;
    apr: number;
    apy: number;
    tvlInZunami: number;
    type: string;
    address: string;
    title?: string;
    icon: string;
    tvl: string;
    tvlUsd: number;
    primaryIcon: string;
    secondaryIcon: string;
}

export interface ChartDataElement {
    title: string;
    link: string;
    color: string;
    value: number;
    tvlInZunami: number;
    address: string;
}

const colors = ['#FC6505', '#12A0FE', '#8EEA19', '#2cd5db', '#1C77F2', '#323232', '#5856d6'];

export const poolsChartdata: { [key: string]: any } = {
    '0xadFa8e4C7004a9373426aC4F37F146a42aE699AB': {
        title: 'Curve - crvUSD/USDT',
        description: '',
        value: 0,
        icon: '/stake-dao.svg',
        primaryIcon: 'crv.svg',
        secondaryIcon: 'usdt.svg',
        address: '0xadFa8e4C7004a9373426aC4F37F146a42aE699AB',
    },
    '0x8D4D612D96D69C9DF83c2607f08f6E361983E598': {
        title: 'Curve - crvUSD/USDC',
        description: '',
        value: 0,
        icon: '/stake-dao.svg',
        primaryIcon: 'crv.svg',
        secondaryIcon: 'usdc.svg',
        address: '0x8D4D612D96D69C9DF83c2607f08f6E361983E598',
    },
    // UZD strats
    '0x7Aa84C31BE1793f2dAb8Dbe36fAa9478aF8851a0': {
        title: 'Vault',
        description: '',
        value: 0,
        icon: '/uzd.svg',
        primaryIcon: 'usdc.svg',
        secondaryIcon: 'usdt.svg',
        address: '0x7Aa84C31BE1793f2dAb8Dbe36fAa9478aF8851a0',
    },
    // zunUSD APS Vault
    '0xF859C621D7fF69DF1E283385DBdE04135EEA0276': {
        title: 'Vault',
        description: '',
        value: 0,
        icon: '/uzd.svg',
        primaryIcon: '/zunusd.svg',
        secondaryIcon: '/1x1.png',
        address: '0xF859C621D7fF69DF1E283385DBdE04135EEA0276',
    },
    '0xcB17C25985E5873Ad5D1114B0E03947fC49e5654': {
        title: 'APS Vault',
        description: '',
        value: 0,
        icon: '/zuneth.svg',
        primaryIcon: '/zuneth.svg',
        secondaryIcon: '/1x1.png',
        address: '0xcB17C25985E5873Ad5D1114B0E03947fC49e5654',
    },
    '0x92cCC61730971Fe2321823aB64f3BC89F5421C5e': {
        title: 'Curve - zunETH/frxETH',
        description: '',
        value: 0,
        icon: '/steth.svg',
        primaryIcon: '/zuneth.svg',
        secondaryIcon: '/frx_eth.png',
        address: '0x92cCC61730971Fe2321823aB64f3BC89F5421C5e',
    },
    '0x72A2394c42521038a91c94f5b4C421fAa45E0719': {
        title: 'Curve - WETH/pxETH',
        description: '',
        value: 0,
        icon: '/weth.svg',
        primaryIcon: '/weth.svg',
        secondaryIcon: '/pxeth.png',
        address: '0x72A2394c42521038a91c94f5b4C421fAa45E0719',
    },
    '0xE527082401705c5b395E3e7a91B0be6e78357159': {
        title: 'Convex Frax Booster - zunETH/frxETH',
        description: '',
        value: 0,
        icon: '/steth.svg',
        primaryIcon: '/zuneth.svg',
        secondaryIcon: '/frx_eth.png',
        address: '0xE527082401705c5b395E3e7a91B0be6e78357159',
    },
    '0x2457C9a3cc6221674c3C91e07A7f193037adDD43': {
        title: 'Curve - zunUSD/crvUSD',
        description: '',
        value: 0,
        icon: '/stake-dao.svg',
        primaryIcon: '/zunusd.svg',
        secondaryIcon: '/crvusd.svg',
        address: '0x2457C9a3cc6221674c3C91e07A7f193037adDD43',
    },
    '0x6167085202ce2B53cB1DE9422fe5248D9D90E8b6': {
        title: 'Curve - zunUSD/fxUSD',
        description: '',
        value: 0,
        icon: '/stake-dao.svg',
        primaryIcon: '/zunusd.svg',
        secondaryIcon: '/fxusd.png',
        address: '0x6167085202ce2B53cB1DE9422fe5248D9D90E8b6',
    },
    '0xCEefF1B0b1863465ff11B62080AC40B544954062': {
        title: 'Curve - ETH/ETHx',
        value: 0,
        icon: '/stake-dao.svg',
        primaryIcon: '/eth.svg',
        secondaryIcon: '/ethx.svg',
        address: '0xCEefF1B0b1863465ff11B62080AC40B544954062',
    },
    '0x950a509528Ae5fc5bca6b20141FB0df2C04A7BF2': {
        title: 'Curve - zunETH/pxETH',
        value: 0,
        icon: '/stake-dao.svg',
        primaryIcon: '/zuneth.svg',
        secondaryIcon: '/pxeth.png',
        address: '0x950a509528Ae5fc5bca6b20141FB0df2C04A7BF2',
    },
    '0x531BbA64373A1B8E5a94BB51ba32CCe1cB42633D': {
        title: 'Llamalend - crvUSD (WETH collateral)',
        value: 0,
        icon: '/stake-dao.svg',
        primaryIcon: '/crvusd.svg',
        secondaryIcon: '/weth.svg',
        address: '0x531BbA64373A1B8E5a94BB51ba32CCe1cB42633D',
    },
    '0xEe58Bf056579786cf11A68c901664A5BA8BDACcf': {
        title: 'Curve - crvUSD/FRAX',
        description: '',
        value: 0,
        icon: '/stake-dao.svg',
        primaryIcon: 'crv.svg',
        secondaryIcon: 'frax.svg',
        address: '0xEe58Bf056579786cf11A68c901664A5BA8BDACcf',
    },
    '0xeDD04c680f9751Db7aF9f5082328Bc9D954316B2': {
        title: 'Vault',
        description: '',
        value: 0,
        icon: '/uzd.svg',
        primaryIcon: '/zunusd.svg',
        secondaryIcon: '/1x1.png',
        address: '0xeDD04c680f9751Db7aF9f5082328Bc9D954316B2',
    },
    '0xd611539aDAb47Afae4a75eB57D192e64729cB305': {
        title: 'Vault',
        description: '',
        value: 0,
        icon: '/uzd.svg',
        primaryIcon: '/zunusd.svg',
        secondaryIcon: '/1x1.png',
        address: '0xd611539aDAb47Afae4a75eB57D192e64729cB305',
    },
    '0x9Ab35326A3854DFBe107935E0558DEb3bCdD647F': {
        title: 'Aerodrome - DOLA/zunUSD',
        description: '',
        value: 0,
        icon: '/dola.svg',
        primaryIcon: '/zunusd.svg',
        secondaryIcon: '/1x1.png',
        address: '0x9Ab35326A3854DFBe107935E0558DEb3bCdD647F',
    },
    '0x1450607DF71fDDB5E27b385CDE82B9E9EED76a69': {
        title: 'Vault',
        description: '',
        value: 0,
        icon: '/uzd.svg',
        primaryIcon: '/zunusd.svg',
        secondaryIcon: '/1x1.png',
        address: '0x1450607DF71fDDB5E27b385CDE82B9E9EED76a69',
    },
    '0x5F8Fc0976FFE5457cCf7651D5FF4cfcA2e86b000': {
        title: 'Vault',
        description: '',
        value: 0,
        icon: '/uzd.svg',
        primaryIcon: '/zunusd.svg',
        secondaryIcon: '/1x1.png',
        address: '0x5F8Fc0976FFE5457cCf7651D5FF4cfcA2e86b000',
    },
};

export function poolInfoToChartElement(pool: PoolInfo, percent: BigNumber, chainId: number|undefined): ChartDataElement {
    const strategyTvl = pool.tvlInZunami ? pool.tvlInZunami : pool.tvl;
    let percentFromTVL = new BigNumber(strategyTvl).dividedBy(percent).toNumber() * 100;

    if (percentFromTVL === Infinity) {
        percentFromTVL = 100;
    }

    let link = `https://etherscan.io/address/${pool.address}`;

    if (chainId === base.id) {
        link = `https://basescan.org/address/${pool.address}`;
    }

    return {
        ...poolsChartdata[pool.address],
        tvlInZunami: strategyTvl,
        value: percentFromTVL,
        tvlInUsd: pool.tvlUsd,
        link: link,
    };
}

export function poolDataToChartData(poolData: Array<PoolInfo>, TVL: BigNumber, chainId: number|undefined = 1) {
    const result = poolData
        .map((pool, index) => {
            const result = {
                ...poolInfoToChartElement(pool, TVL, chainId),
                color: colors[index],
                ...pool,
                // @ts-ignore
            };

            if (!result.address && result.type === '') {
                result.address = '0x0Bcf22e3d86595FFaFbA49947280C0b802843550';
            }

            return result;
        })
        .filter((el) => el.value > 0)
        .sort((a, b) => (a.tvlInZunami > b.tvlInZunami ? -1 : 1));

    return result;
}

export function formatPoolApy(rawValue: number) {
    let result = rawValue.toFixed(2);

    if (rawValue > 500) {
        result = '500+';
    }

    return result;
}

export function getPoolPrimaryIcon(pool: any) {
    let result = '/clever_analytics.png';
    const analytics = pool.analytics.data ? pool.analytics.data : pool.analytics;

    if (analytics && analytics.coinsMarketData.stableCoin) {
        result = analytics.coinsMarketData.stableCoin.image;
    }

    if (pool.type === 'STAKEDAO_CRVUSD_USDT') {
        result = '/0xf939e0a03fb07f59a73314e73794be0e57ac1b4e.png';
    }

    return result;
}
