import { useState, useRef, useMemo, useCallback } from 'react';
import '../InfoBlock/InfoBlock.scss';
import './CoinSelector.scss';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as HintIcon } from '../../assets/info.svg';
import { base } from '../NetworkSelector/NetworkSelector';
import { Address } from 'viem';
import { getBaseNewZunUsdAddress, getBaseOldZunUsdAddress } from '../../utils/zunami';

interface CoinSelectorProps {
    mode: string;
    name: string;
    chainId: number | undefined;
    onCoinSelect?: Function;
    hideToggler?: boolean;
    stakingMode: string;
    hideNative?: boolean;
}

interface CoinProps {
    title: string;
    coinName: string;
    onCoinSelect: Function;
    selected: boolean;
    address?: Address;
}

function displayCoinName(coinCode: string) {
    let result = coinCode;

    switch (coinCode) {
        case 'zunUSD':
            result = 'zunUSD';
            break;
        case 'zunETH':
            result = 'zunETH';
            break;
    }

    return result;
}

function renderCoinItem(props: CoinProps & React.HTMLProps<HTMLButtonElement>) {
    let icon = `${props.coinName.toLowerCase()}.svg`;

    if (props.address && props.address === getBaseOldZunUsdAddress()) {
        icon = 'zunusd_old.svg';
    }
    
    return (
        <div
            className={`coin-item ${props.className} ${
                props.coinName === 'FRAX' ? 'disabled' : ''
            } ${props.selected ? 'selected' : ''}`}
            onClick={() => props.onCoinSelect(props.coinName, props.address)}
        >
            <img src={icon} alt="" />
            <div className={'coinName'}>{displayCoinName(props.title)}</div>
        </div>
    );
}

function getCoinName(name: string, mode: string) {
    let result = name;

    if (name === 'UZD') {
        result = 'zunUSD';
    }

    if (name === 'ZETH') {
        result = 'zunETH';
    }

    if (name === 'apsZunUSDLP') {
        result = 'APS zunUSD LP';
    }

    if (name === 'apsZunETHLP') {
        result = 'APS zunETH LP';
    }

    if (name === 'ZAPSLP') {
        result = 'zunUSD';
    }

    if (name === 'zunUSD_base_old' || name === 'zunUSD_base_new') {
        result = 'zunUSD';
    }

    return result;
}

export const CoinSelector = (
    props: CoinSelectorProps & React.HTMLProps<HTMLButtonElement>
): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const target = useRef(null);
    const [showHint, setShowHint] = useState(false);

    const onToggle = () => {
        if (!props.hideToggler) {
            setIsOpen(!isOpen);
        }
    };

    const onCoinSelect = useCallback((coinName: string, address: Address) => {
        if (props.onCoinSelect) {
            props.onCoinSelect(coinName, address);
        }
    }, [props]);

    const tooltipText = useMemo(() => {
        let availableCoins = 'USDT/USDC/DAI';

        if (props.stakingMode !== 'UZD') {
            availableCoins = 'WETH/frxETH/ETH';
        }

        return `You can use the ZAP function to make a deposit in
                        ${availableCoins}. Utilizing the ZAP function involves
                        the automatic conversion of ${availableCoins} into
                        zunStable, followed by the deposit. Please note that
                        the gas cost for this operation will be higher than
                        a native deposit.`;
    }, [props.stakingMode]);

    const renderCoins = useMemo(() => {
        let result = (
            <div className="row mt-3 mt-md-2">
                <div className="col-12 col-xl-6">
                    {props.stakingMode === 'UZD' &&
                        renderCoinItem({
                            title: 'USDC',
                            coinName: 'USDC',
                            onCoinSelect,
                            className: 'mt-2 mb-2',
                            selected: props.name === 'USDC',
                        })}
                    {props.stakingMode === 'UZD' &&
                        renderCoinItem({
                            title: 'DAI',
                            coinName: 'DAI',
                            onCoinSelect,
                            className: 'mt-0',
                            selected: props.name === 'DAI',
                        })}
                    {props.stakingMode === 'ZETH' &&
                        renderCoinItem({
                            title: 'WETH',
                            coinName: 'WETH',
                            onCoinSelect,
                            className: 'mt-0',
                            selected: props.name === 'WETH',
                        })}
                    {props.stakingMode === 'ZETH' &&
                        renderCoinItem({
                            title: 'FRXETH',
                            coinName: 'FRXETH',
                            onCoinSelect,
                            className: 'mt-0',
                            selected: props.name === 'FRXETH',
                        })}
                </div>
                <div className="col-12 col-xl-6">
                    {props.stakingMode === 'UZD' &&
                        renderCoinItem({
                            title: 'USDT',
                            coinName: 'USDT',
                            onCoinSelect,
                            className: 'mt-2',
                            selected: props.name === 'USDT',
                    })}
                    {props.stakingMode === 'UZD' &&
                        renderCoinItem({
                            title: 'CRVUSD',
                            coinName: 'CRVUSD',
                            onCoinSelect,
                            className: 'mt-2 mb-0',
                            selected: props.name === 'CRVUSD',
                    })}
                    {props.stakingMode === 'ZETH' &&
                        renderCoinItem({
                            title: 'ETH',
                            coinName: 'ETH',
                            onCoinSelect,
                            className: 'mt-0',
                            selected: props.name === 'ETH',
                        })}
                </div>
            </div>
        );

        if (props.chainId === base.id) {
            return (
                <div className="row mt-3 mt-md-2">
                    <div className="col-12 col-xl-6">
                        {
                            renderCoinItem({
                                title: 'zunUSD',
                                coinName: 'zunUSD',
                                onCoinSelect,
                                className: 'mt-2 mt-lg-2',
                                selected: props.name === 'uzd',
                                address: getBaseNewZunUsdAddress(),
                            })
                        }
                    </div>
                </div>
            );
        }

        return result;
    }, [props.chainId, props.stakingMode, props.name, onCoinSelect]);

    const iconName = useMemo(() => {
        let result = `${props.name.toLowerCase()}.svg`;

        if (props.name === 'zunUSD_base_new') {
            result = 'zunusd.svg';
        }

        if (props.name === 'zunUSD_base_old') {
            result = 'zunusd_old.svg';
        }

        return result;
    }, [props.name]);

    return (
        <div className={`CoinSelector ${props.className ? props.className : ''}`} onClick={onToggle}>
            <img src={iconName} alt="" />
            <div className={'coinName'}>{getCoinName(props.name, props.mode)}</div>
            {props.mode === 'deposit' && !props.hideToggler && (
                <svg
                    width="14"
                    height="5"
                    viewBox="0 0 14 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="selector"
                >
                    <path
                        d="M1 1L7 4L13 1"
                        stroke="#404040"
                        strokeWidth="1.1"
                        strokeLinecap="round"
                    />
                </svg>
            )}
            {isOpen && props.mode === 'deposit' && (
                <div className="Coin-Selector__Items big">
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="h-100 d-flex flex-column l-col">
                                <div className="title">
                                    {props.chainId === 1 || !props.chainId ? 'Native' : ' Legacy Token'}
                                </div>
                                <div className="row mt-3 mt-md-2">
                                    <div className="col-7">
                                        <div className="flex-grow-1 d-flex align-items-start">
                                            {
                                                (props.chainId === 1 || !props.chainId) && renderCoinItem({
                                                    title: props.stakingMode === 'UZD'
                                                            ? 'zunUSD'
                                                            : 'zunETH',
                                                    coinName:
                                                        props.stakingMode === 'UZD'
                                                            ? 'zunUSD'
                                                            : 'zunETH',
                                                    onCoinSelect,
                                                    className: 'mt-2 mt-lg-3',
                                                    selected: props.name === 'uzd',
                                                })
                                            }
                                            {/* {
                                                props.chainId === base.id && renderCoinItem({
                                                    title: 'zunUSD',
                                                    coinName: 'zunUSD',
                                                    onCoinSelect,
                                                    className: 'mt-2 mt-lg-2',
                                                    selected: props.name === 'uzd',
                                                    address: getBaseOldZunUsdAddress(),
                                                })
                                            } */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <div className={`d-flex gap-1 align-items-center mt-4 mt-md-0`}>
                                <span className={`title me-1`}>
                                    {(props.chainId === 1 || !props.chainId) ? 'ZAP (Swap + Deposit)' : 'Chainlink CCIP'}
                                </span>
                                {
                                    (props.chainId === 1 || !props.chainId) &&
                                        <div ref={target} onClick={() => setShowHint(!showHint)}>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                    <Tooltip>
                                                        {tooltipText}
                                                    </Tooltip>
                                                }
                                            >
                                                <HintIcon />
                                            </OverlayTrigger>
                                        </div>
                                }
                            </div>
                            {renderCoins}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
