import { ReactElement, useEffect, useMemo, useState } from 'react';
import './NetworkSelector.scss';
import { ReactComponent as ETHLogo } from './eth_logo.svg';
import { ReactComponent as BSCLogo } from './bsc_logo.svg';
import { ReactComponent as PLGLogo } from './polygon_logo.svg';
import { ReactComponent as BaseLogo } from './base_logo.svg';
import { log } from '../../utils/logger';
import { useConnect, useAccount, useNetwork } from 'wagmi';

interface NetworkSelectorProps extends React.HTMLProps<HTMLDivElement> {
    onNetworkChange?: Function;
    hideActiveNetwork: boolean;
    autoChange: boolean;
    customNetworksList?: Array<Network>;
}

export interface Network {
    id?: number;
    key: string;
    value: string;
    icon: ReactElement;
}

export const base = {
    id: 8453,
    key: '0x2105',
    value: 'Base',
    icon: <BaseLogo />,
};

export const networks = [
    {
        id: 1,
        key: '0x1',
        value: 'Ethereum',
        icon: <ETHLogo />,
    },
    {
        id: 56,
        key: '0x38',
        value: 'Binance',
        icon: <BSCLogo />,
    },
    {
        key: '0x3',
        value: 'Ropsten',
        icon: <ETHLogo />,
    },
    {
        key: '0x4',
        value: 'Rinkeby Testnet',
        icon: <ETHLogo />,
    },
    {
        key: '0x2a',
        value: 'Kovan Testnet',
        icon: <ETHLogo />,
    },
    {
        key: '0x5',
        value: 'Goerli Testnet',
        icon: <ETHLogo />,
    },
    {
        key: '0x61',
        value: 'Smart Chain Testnet',
        icon: <ETHLogo />,
    },
    {
        key: '0x89',
        value: 'Polygon',
        icon: <PLGLogo />,
    },
    {
        key: '0x13881',
        value: 'Mumbai',
        icon: <ETHLogo />,
    },
    {
        key: '0xa86a',
        value: 'Avalanche',
        icon: <ETHLogo />,
    },
    {
        key: '0xa869',
        value: 'Avalanche Testnet',
        icon: <ETHLogo />,
    },
    {
        key: '0xfa',
        value: 'Fantom',
        icon: <ETHLogo />,
    },
    {
        key: '0x504',
        value: 'Moonbeam',
        icon: <ETHLogo />,
    },
    base,
];

export const getNetworkById = (id: number) : Network => {
    let result = networks.filter(network => parseInt(network.key, 16) === id);
    return result.length ? result[0] : networks[0];
}

export const supportedChainIds = [1, 8453];

export const NetworkSelector: React.FC<NetworkSelectorProps> = ({
    className,
    onNetworkChange,
    autoChange = true,
    hideActiveNetwork = false,
    customNetworksList,
}) => {
    const [activeNetwork, setActiveNetwork] = useState<Network>(networks[0]);
    const eth = window.ethereum;
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : 1;

    const [chainSupported, setChainSupported] = useState(false);
    const networksList = customNetworksList ? customNetworksList : networks;
    const availableNetworks: Array<Network> = supportedChainIds.map(chID => getNetworkById(chID));

    useEffect(() => {
        if (!chainId) {
            return;
        }

        const defaultNetwork = networks.filter(
            (network) => parseInt(network.key, 16) === chainId
        )[0];

        if (!hideActiveNetwork) {
            setActiveNetwork(defaultNetwork);
        }

        setChainSupported(supportedChainIds.indexOf(chainId) !== -1);
    }, [chainId, activeNetwork, hideActiveNetwork]);

    if (!activeNetwork) {
        return <div></div>;
    }

    return (
        <div className={`NetworkSelector ${className}`}>
            <svg className="selector me-2" width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5.5 4L10 1" stroke="#686868" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            {chainSupported && activeNetwork.icon}
            {!chainSupported && <span>?</span>}
            <span>{activeNetwork.value}</span>
            <select
                value={activeNetwork.key}
                onChange={async (e) => {
                    // @ts-ignore
                    const selectedValue = e?.nativeEvent?.target?.value;
                    const network = networks.filter((network) => network.key === selectedValue)[0];
                    setActiveNetwork(network);
                    log(`Network switch to ${selectedValue} (select onChange)`);

                    if (eth && eth.request && autoChange) {
                        try {
                            await eth.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: selectedValue }],
                            });
                        } catch (e) {
                            let chainParams = {
                                chainId: '0x8453',
                                chainName: 'Base Mainnet',
                                nativeCurrency: {
                                    name: 'Ethereum',
                                    symbol: 'ETH',
                                    decimals: 18,
                                },
                                rpcUrls: ['https://mainnet.base.org'],
                                blockExplorerUrls: ['https://base.blockscout.com'],
                            };

                            window.ethereum
                                .request({
                                    method: 'wallet_addEthereumChain',
                                    params: [chainParams],
                                })
                                .catch((error: any) => {
                                    log(error);
                                });
                        }
                    }
                    if (onNetworkChange) {
                        onNetworkChange(network);
                    }
                }}
            >
                {availableNetworks.map((network) => (
                    <option key={network.key} value={network.key}>
                        {network.value}
                    </option>
                ))}
                {/* <option key={networks[0].key} value={networks[0].key}>
                    {networks[0].value}
                </option> */}
            </select>
        </div>
    );
};
