import { useState, useEffect } from 'react';

export const useCircSupply = () => {
    const [supply, setSupply] = useState<number>(0);

    useEffect(() => {
        fetch('https://api.zunami.io/api/supply/circulating')
            .then((response) => response.json())
            .then((data) => setSupply(data));
    }, []);

    return supply;
};
