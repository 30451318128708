import { useEffect, useRef, useState } from 'react';
import './ApyChart.scss';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
ChartJS.defaults.font.size = 10;

export interface ChartDataProps {
    labels?: Array<any>,
    datasets: Array<any>,
}

interface ChartProps {
    data: ChartDataProps;
    onRangeChange?: Function;
    legend?: boolean;
    className?: string;
    soon?: boolean;
}

interface RangeItem {
    value: String;
    title: String;
}

function onRangeChange(e: any, handler: Function | undefined) {
    const range = e.target.dataset.range;

    if (handler) {
        handler(range);
    }
}

function renderRanges(
    items: Array<RangeItem>,
    currentState: string,
    onChangeHandler: Function | undefined
) {
    return items.map((item: RangeItem, index: number) => (
        <span
            key={index}
            className={currentState === item.value ? 'active' : ''}
            data-range={item.value}
            onClick={(e) => {
                onRangeChange(e, onChangeHandler);
            }}
        >
            {item.title}
        </span>
    ));
}

const ranges = [
    { value: 'week', title: '1 week' },
    { value: 'month', title: '1 month' },
    { value: 'all', title: 'All time' },
];

let paddings = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
};

if (document.body.clientWidth > 1024) {
    paddings.top = 20;
    paddings.bottom = 20;
}

const chartOptions = {
    responsive: true,
    maintainAspectRatio: document.body.clientWidth > 1024 ? false : true,
    layout: {
        padding: paddings,
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: function (context: any) {
                    return `${context.dataset.label}: ${context.dataset.data[context.dataIndex].toFixed(2)}%` || '';
                },
            },
        },
        legend: {
            display: false,
        }
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                font: {
                    size: 9,
                },
            },
        },
        y: {
            // display: false,
            grid: {
                display: false,
            },
            min: 0,
            // max: 60,
            ticks: {
                //@ts-ignore
                callback: function (val) {
                    return `${val}%`;
                },
                stepSize: 3,
            },
        },
    },
    legend: {
        display: false,
    }
};

export const ApyChart = (props: ChartProps): JSX.Element => {
    const [currentRange, setCurrentRange] = useState('week');
    const chartInstance = useRef(null);
    const [datasetStates, setDatasetStates] = useState<Array<boolean>>([true, true, true]);

    const handleClick = (e: any, index: any) => {
        const ctx = chartInstance.current;

        // @ts-ignore
        const meta = ctx.getDatasetMeta(index);
        meta.hidden = !meta.hidden;

        const newValues = [...datasetStates];
        newValues[index] = !newValues[index];
        setDatasetStates(newValues);
        // @ts-ignore
        chartInstance.current.update();
    };

    useEffect(() => {
        if (props.data.datasets.length) {
            setDatasetStates(props.data.datasets.map(dataset => true));
        }
    }, [props.data.datasets]);

    return (
        <div className={`ApyChart ${props.className ? props.className : ''}`}>
            {
                props.soon && (
                    <div className="soon-placeholder-block">soon</div>
                )
            }
            <div className="ApyChart__Header">
                <div className="ApyChart__Selector">
                    {renderRanges(ranges, currentRange, (e: string) => {
                        setCurrentRange(e);

                        if (props.onRangeChange) {
                            props.onRangeChange(e);
                        }
                    })}
                </div>
            </div>
            <div id="legend-container" className={`${props.legend ? '' : 'd-none'}`}>
                <ul className="legend-container">
                    {
                        props.data.datasets.map((dataset, index) => 
                            <li key={dataset.label} >
                                <label >
                                    <input
                                        type="checkbox"
                                        checked={datasetStates[index]}
                                        onChange={(e) => handleClick(e, index)}
                                    />
                                    <div className="icon" style={{ backgroundColor: dataset.borderColor }}></div>
                                    <span>{dataset.label}</span>
                                </label>
                            </li>
                        )
                    }
                </ul>
            </div>
            <Line ref={chartInstance}
                className="ApyChart__Chart"
                options={chartOptions}
                data={props.data}
            />
        </div>
    );
};
