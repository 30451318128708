import { Address, useAccount, useNetwork, useWalletClient } from 'wagmi';
import apsAbi from '../actions/abi/aps.json';
import { useMemo } from 'react';
import { contractAddresses } from '../sushi/lib/constants';
import BigNumber from 'bignumber.js';
import {
    BIG_TEN,
    NULL_ADDRESS,
    UZD_DECIMALS,
} from '../utils/formatbalance';
import { log } from '../utils/logger';
import { base } from '../components/NetworkSelector/NetworkSelector';
import { getChainClient } from '../utils/zunami';

const MIN_AMOUNT_THRESHOLD = 0.995;

const useUnstake = (
    shares: string | BigNumber,
    minTokenAmounts: Array<string> = ['0', '0', '0', '0', '0'],
    receiver: Address,
    stakingMode: string
) => {
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const { address: account } = useAccount();
    const wallet = useWalletClient();

    const contractAddress = useMemo(() => {
        if (!chainId) {
            return contractAddresses.aps[1];
        }

        if (stakingMode === 'ZETH') {
            return contractAddresses.ethAps[chainId] ?? contractAddresses.ethAps[1];
        }

        if (chainId === base.id) {
            return contractAddresses.aps[base.id];
        }

        return contractAddresses.aps[chainId] ?? contractAddresses.aps[1];
    }, [chainId, stakingMode]);

    async function onUnstake() {
        let sumToWithdraw = '0';

        if (typeof (shares) === 'string') {
            sumToWithdraw = new BigNumber(shares)
                .multipliedBy(BIG_TEN.pow(UZD_DECIMALS))
                .dp(0, BigNumber.ROUND_DOWN)
                .toString();
        } else {
            sumToWithdraw = shares.toString();
        }

        // fresh token price retreival
        const lpPrice: BigInt = await getChainClient(chainId).readContract({
            // @ts-ignore
            address: contractAddress,
            abi: apsAbi,
            functionName: "tokenPrice",
            account: account || NULL_ADDRESS,
        });

        const minAmount = new BigNumber(sumToWithdraw)
            .multipliedBy(new BigNumber(lpPrice.toString()).dividedBy(BIG_TEN.pow(18)))
            .multipliedBy(MIN_AMOUNT_THRESHOLD);

        minTokenAmounts[0] = minAmount.dp(0, BigNumber.ROUND_DOWN).toString();

        log(`${contractAddress}.withdraw(${sumToWithdraw}, [${minTokenAmounts}], '${receiver}')`);

        // @ts-ignore
        return await wallet.data.writeContract({
            address: contractAddress,
            chain: chain,
            abi: apsAbi,
            functionName: 'withdraw',
            args: [sumToWithdraw, minTokenAmounts, receiver],
            account: account || NULL_ADDRESS,
        });
    }

    return {
        withdraw: onUnstake,
    };
};

export default useUnstake;
