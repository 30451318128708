import { useEffect, useState } from 'react';
import { uzdStakingInfoUrl } from '../api/api';

interface AggregatedInfo {
    zunUSDApsStaking: {
        apr: number,
        tvlUsd: number,
    },
    staking: {
        apr: number,
        tvlUsd: number,
        monthlyAvgApr: number,
    },
    zunUSDAps: {
        apy: number,
    },
    zunETHAps: {
        apy: number,
    },
    zunUSD: {
        apr: number,
        tvlUsd: number,
    },
    zunETH: {
        apr: number,
        tvlUsd: number,
    },
}

const useTvl = () => {
    const [tvl, setTvl] = useState<string>('0');
    const [info, setInfo] = useState<AggregatedInfo>({
        zunUSDApsStaking: {
            apr: 0,
            tvlUsd: 0,
        },
        staking: {
            apr: 0,
            tvlUsd: 0,
            monthlyAvgApr: 0,
        },
        zunUSDAps: {
            apy: 0,
        },
        zunETHAps: {
            apy: 0,
        },
        zunUSD: {
            apr: 0,
            tvlUsd: 0,
        },
        zunETH: {
            apr: 0,
            tvlUsd: 0,
        }
    });

    useEffect(() => {
        try {
            fetch(uzdStakingInfoUrl)
                .then(data => data.json())
                .then((data) => {
                    setTvl(data.totalTvlUsd);
                    setInfo(data.info);
                })
                .catch(e => {
                    setTvl('0');
                });
        } catch (e) {

        }
    }, []);

    return {
        tvl,
        info,
    };
};

export default useTvl;
