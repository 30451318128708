import Accordion from 'react-bootstrap/Accordion';
import { MicroCard } from '../../components/MicroCard/MicroCard';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './StakingPool.scss';
import { StakingPoolInfo } from '../../hooks/useStakingPools';
import useAllowance from '../../hooks/useAllowance';
import { useNetwork, useWalletClient } from 'wagmi';
import { getZunEthApsAddress, getZunTokenAddress, getZunUsdApsAddress } from '../../utils/zunami';
import { useEffect, useMemo, useState } from 'react';
import { APPROVE_SUM } from '../../sushi/utils';
import useApprove from '../../hooks/useApprove';
import { log } from '../../utils/logger';
import useStake from '../../hooks/useStake';
import { BIG_TEN, BIG_ZERO, NULL_ADDRESS, UZD_DECIMALS, bigNumberToNumber, getFullDisplayBalance } from '../../utils/formatbalance';
import { Toast, ToastContainer } from 'react-bootstrap';
import { getScanAddressByChainId } from '../FastDepositForm/types';
import BigNumber from 'bignumber.js';
import { waitForTransaction } from '@wagmi/core';
import { Address } from 'wagmi';
import earnPoolABI from '../../actions/abi/zun/earn-pool.json';
import { Preloader } from '../Preloader/Preloader';
import useAccount from '../../hooks/useAccountOverride';
import { Link } from 'react-router-dom';

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
});

interface StakingPoolProps extends StakingPoolInfo {
    index: string;
    coinAddress: Address;
    coinPrices?: {
        zunUSD: number;
        ZETH: number;
    }
}

export function renderToasts(
    transactionError: boolean,
    setTransactionError: Function,
    chainId: number,
    transactionId: string | undefined,
    setTransactionId: Function
) {
    return (
        <ToastContainer position={'top-end'} className={'toasts mt-3 me-3'}>
            {transactionError && (
                <Toast onClose={() => setTransactionError(false)} delay={10000} autohide>
                    <Toast.Body>Sorry, we couldn't complete the transaction</Toast.Body>
                </Toast>
            )}
            {transactionId && (
                <Toast onClose={() => setTransactionId(undefined)} delay={15000} autohide>
                    <Toast.Body>
                        Success! Check out the{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://${getScanAddressByChainId(chainId)}/tx/${transactionId}`}
                        >
                            transaction
                        </a>
                    </Toast.Body>
                </Toast>
            )}
        </ToastContainer>
    );
}

export function parseSum(input: string) {
    const val = parseFloat(input);
    return Number.isNaN(val) ? 0 : parseFloat(input);
}

function poolActionDesc(index: string) {
    let result = (
        <div className="pool-action-desc">
            Deposit liquidity into the <Link to="/">zunUSD APS</Link>, and then stake your LP tokens here to earn ZUN on top of staked zunUSD native yield.
        </div>
    );

    switch (index) {
        case '1':
            result = (
                <div>
                Deposit liquidity into the <Link to="/">zunUSD ETH</Link>, and then stake your LP tokens here to earn ZUN on top of staked zunUSD native yield.
                </div>
            )
            break;
    }

    return result;
}

export const StakingPool: React.FC<StakingPoolProps & React.HTMLProps<HTMLDivElement>> = ({
    className,
    index,
    title,
    platform,
    tvl,
    apr,
    apy,
    claimed,
    unclaimed,
    balance,
    address,
    maxStakingSum,
    secondaryIcons,
    coinAddress,
    balanceUsd,
}) => {
    const { address: account } = useAccount();
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const [pendingTx, setPendingTx] = useState(false);
    const [transactionError, setTransactionError] = useState(false);
    const [transactionId, setTransactionId] = useState<string | undefined>(undefined);
    const [depositSum, setDepositSum] = useState('0');
    const [withdrawSum, setWithdrawSum] = useState('0');
    const [stakeAll, setStakeAll] = useState(false);
    const wallet = useWalletClient();
    const [withdrawAll, setWithdrawAll] = useState(false);

    const stakingAllowance = useAllowance(
        coinAddress,
        account,
        address,
        chainId
    );

    log(`Staking pool ${address} allowance: ${stakingAllowance.toString()}`);

    const stakingApproved = useMemo(() => {
        const requestedSum = stakeAll ? maxStakingSum : new BigNumber(parseSum(depositSum)).multipliedBy(BIG_TEN.pow(18));

        return requestedSum.isLessThanOrEqualTo(stakingAllowance);
    }, [stakingAllowance, depositSum, stakeAll, maxStakingSum]);

    const stakingEnabled = useMemo(() => {
        const depSum = Number(depositSum);

        if (!depSum || isNaN(depSum) || !stakingApproved) {
            return false;
        }

        return (
            depSum <= bigNumberToNumber(maxStakingSum)
        );
    }, [depositSum, stakingApproved, maxStakingSum]);

    const withdrawEnabled = useMemo(() => {
        const wSum = Number(withdrawSum);

        if (!wSum || isNaN(wSum)) {
            return false;
        }

        return (
            wSum <= bigNumberToNumber(balance)
        );
    }, [withdrawSum, balance]);

    // approve
    const {
        data: approveResult,
        isLoading: isApproving,
        write: approve,
    } = useApprove(
        coinAddress,
        address,
        stakeAll ? maxStakingSum.toString() : new BigNumber(parseSum(depositSum)).multipliedBy(BIG_TEN.pow(UZD_DECIMALS)).toString(),
        chainId
    );

    // wait for approve, then unblock button
    useEffect(() => {
        if (approveResult) {
            setPendingTx(true);

            waitForTransaction({
                hash: approveResult?.hash,
            }).then(() => {
                setTransactionId(approveResult.hash)
                setPendingTx(false);
            });
        }
    }, [approveResult]);

    return (
        <Accordion.Item eventKey={index}>
            {renderToasts(
                transactionError,
                setTransactionError,
                chainId ?? 1,
                transactionId,
                setTransactionId
            )}
            <Accordion.Header>
                <div className="d-flex pool-row">
                    <div className="d-flex align-items-center pool">
                        <div className="header">Pool name</div>
                        <img src="/zun.svg" alt="" className="primary-icon" />
                        <div className="divider ms-2 me-2"></div>
                        <div className="coins">
                            {
                                secondaryIcons?.map(icon => <img key={icon} src={icon} alt="" />)
                            }
                        </div>
                        <div className="titles">
                            <div className="primary">{title}</div>
                            <div className="secondary">{platform}</div>
                        </div>
                    </div>
                    <div className="tvl vela-sans">
                        <div className="header">TVL</div>
                        {
                            tvl && (
                                <div>${Number(tvl).toLocaleString('en', { maximumFractionDigits: 2, })}</div>
                            )
                        }
                        {
                            !tvl && <span>soon</span>
                        }
                    </div>
                    <div className="apr vela-sans ">
                        <div className="header">APR</div>
                        <div>
                            <div>{(apr === -1 || typeof(apr) === 'undefined') ? 'soon' : `${apr.toString().substring(0,5)}%`}</div>
                            {
                                apy && (
                                    <div className="apy">(+{apy.toFixed(2)}% APY)</div>
                                )
                            }
                        </div>
                    </div>
                    <div className="deposit-val vela-sans">
                        <div className="header">Your deposit</div>
                        <div>{getFullDisplayBalance(balance, 18, 2)} LP</div>
                        <div>&nbsp;&nbsp;(≈${Number(balanceUsd).toLocaleString('en', { maximumFractionDigits: 2, })})</div>
                    </div>
                    <div className="claimed vela-sans">
                        <div className="header">Claimed</div>
                        <div className="d-flex align-items-center gap-2">
                            <img src="/zun.svg" alt="" />
                            <div>{getFullDisplayBalance(claimed)} ZUN</div>
                        </div>
                    </div>
                    <div className="unclaimed vela-sans">
                        <div className="header">Unclaimed</div>
                        <div className="">
                            {
                                unclaimed.isGreaterThan(BIG_ZERO) && account && (
                                    <button
                                        className="zun-button"
                                        onClick={async (e) => {
                                            if (!wallet.data) {
                                                return;
                                            }

                                            // e.preventDefault();
                                            e.stopPropagation();

                                            try {
                                                setPendingTx(true);

                                                const txHash = await wallet.data.writeContract({
                                                    address,
                                                    chain: chain,
                                                    abi: earnPoolABI,
                                                    functionName: 'claim',
                                                    args: [account],
                                                    account,
                                                });

                                                setTransactionId(txHash);
                                            } catch (e: any) {
                                                log(`[SMART] Error while claiming: ${e.message}`);
                                            }

                                            setPendingTx(false);
                                        }}
                                    >Claim {getFullDisplayBalance(unclaimed, 18, 3)} ZUN</button>
                                )
                            }
                            {
                                unclaimed.isEqualTo(BIG_ZERO) && (
                                    <div className="d-flex align-items-center gap-2">
                                        <img src="/zun.svg" alt="" />
                                        <div>{getFullDisplayBalance(unclaimed)} ZUN</div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex d-lg-none gap-2 w-100 first-row-counters mt-3">
                    <MicroCard title="TVL" value={`$${Number(tvl).toLocaleString('en', { maximumFractionDigits: 2, })}`} />
                    <MicroCard title="APR" value={`${apr}%`} hint="Some tooltip content" />
                    <MicroCard title="Deposit" value={getFullDisplayBalance(balance)} />
                </div>
                <div className="d-flex d-lg-none gap-2 w-100 second-row-counters mt-3">
                    <MicroCard title="Already claimed" value={getFullDisplayBalance(claimed)} />
                    <MicroCard
                        title="Unclaimed"
                        value={getFullDisplayBalance(unclaimed)}
                    />
                </div>
            </Accordion.Header>
            <Accordion.Body>
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <Tabs
                            defaultActiveKey="stake"
                            transition={false}
                            id="noanim-tab-example"
                            className="action-tabs"
                        >
                            <Tab eventKey="stake" title="Stake">
                                <div className="row">
                                    <div className="action-col col-xs-12 col-md-6">
                                        <div className="action-hint mt-3">{poolActionDesc(index)}</div>
                                        <div className="input mt-3">
                                            <div className="coins ps-3">
                                                {secondaryIcons?.map(icon => <img key={icon} src={icon} alt="" />)}
                                            </div>
                                            <input
                                                type="text"
                                                value={depositSum}
                                                inputMode={'decimal'}
                                                autoComplete={'off'}
                                                autoCorrect={'off'}
                                                pattern={'^[0-9]*[.,]?[0-9]*$'}
                                                placeholder={'0.00'}
                                                min={0}
                                                minLength={1}
                                                maxLength={8}
                                                onChange={(e) => {
                                                    const sum = e.target.value;
                                                    const regex = /^[0-9]*[.,]?[0-9]*$/;

                                                    if (!sum) {
                                                        setDepositSum('');
                                                        return;
                                                    }

                                                    if (regex.test(sum)) {
                                                        setDepositSum(sum);
                                                    }

                                                    setStakeAll(false);
                                                }}
                                            />
                                            <button
                                                className="max"
                                                onClick={(e) => {
                                                    setDepositSum(
                                                        // bigNumberToNumber(maxStakingSum).toString()
                                                        new BigNumber(maxStakingSum).dividedBy(BIG_TEN.pow(18)).toPrecision()
                                                    );

                                                    setStakeAll(true);
                                                }}
                                            >
                                                MAX
                                            </button>
                                        </div>
                                    </div>
                                    <div className="action-col col-xs-12 col-md-6">
                                        <div className="steps mt-3">
                                            <div className="digits">
                                                <div className="digit">1</div>
                                                <div className="digit">2</div>
                                            </div>
                                            <div className="d-flex gap-2 mt-3">
                                                <button
                                                    className={`zun-button ${
                                                        stakingApproved ? 'disabled' : ''
                                                    }`}
                                                    onClick={async () => {
                                                        setPendingTx(true);

                                                        if (approve) {
                                                            approve();
                                                        }

                                                        setPendingTx(false);
                                                    }}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                    className={`zun-button ${
                                                        !stakingEnabled ? 'disabled' : ''
                                                    }`}
                                                    onClick={async () => {
                                                        if (!wallet || !wallet.data) {
                                                            return;
                                                        }

                                                        setPendingTx(true);

                                                        try {
                                                            const args = [
                                                                new BigNumber(parseSum(depositSum)).multipliedBy(BIG_TEN.pow(UZD_DECIMALS)).toString(),
                                                                account,
                                                            ];

                                                            if (stakeAll) {
                                                                args[0] = maxStakingSum.toString();
                                                            }

                                                            log(`[SMART] Pool deposit(${args.join(',')})`);

                                                            const txHash = await wallet.data.writeContract({
                                                                address,
                                                                chain: chain,
                                                                abi: earnPoolABI,
                                                                functionName: 'deposit',
                                                                args: args,
                                                                account,
                                                            });

                                                            setTransactionId(txHash);

                                                            waitForTransaction({ hash: txHash }).then(() => {
                                                                setPendingTx(false);
                                                            });
                                                        } catch (error: any) {
                                                            setTransactionError(error.message);
                                                            alert(error.message);
                                                            log(`❗️ Error while deposit: ${error.message}`);
                                                            setPendingTx(false);
                                                        }
                                                    }}
                                                >
                                                    Stake
                                                </button>
                                                {pendingTx && <Preloader />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="unstake" title="Unstake">
                                <div className="row">
                                    <div className="action-col col-xs-12 col-md-6">
                                        <div className="action-hint mt-3">Withdraw your LP tokens</div>
                                        <div className="input mt-3">
                                            <div className="coins ps-3">
                                                {secondaryIcons?.map(icon => <img key={icon} src={icon} alt="" />)}
                                            </div>
                                            <input
                                                type="text"
                                                value={withdrawSum}
                                                inputMode={'decimal'}
                                                autoComplete={'off'}
                                                autoCorrect={'off'}
                                                pattern={'^[0-9]*[.,]?[0-9]*$'}
                                                placeholder={'0.00'}
                                                min={0}
                                                minLength={1}
                                                maxLength={8}
                                                onChange={(e) => {
                                                    setWithdrawSum(e.target.value);
                                                    setWithdrawAll(false);
                                                }}
                                            />
                                            <button
                                                className="max"
                                                onClick={(e) => {
                                                    setWithdrawSum(
                                                        getFullDisplayBalance(balance, 18, 18)
                                                    );

                                                    setWithdrawAll(true);
                                                }}
                                            >
                                                MAX
                                            </button>
                                        </div>
                                    </div>
                                    <div className="action-col col-xs-12 col-md-6">
                                        <div className="steps mt-3">
                                            <div className="digits" style={{ opacity: 0 }}>
                                                <div className="digit">1</div>
                                                <div className="digit">2</div>
                                            </div>
                                            <div className="d-flex justify-content-center justify-content-md-start gap-2 mt-3">
                                                {/* <button className="zun-button">Approve</button> */}
                                                <button
                                                    className={`zun-button ${
                                                        !withdrawEnabled ? 'disabled' : ''
                                                    }`}
                                                    onClick={async () => {
                                                        if (!wallet || !wallet.data) {
                                                            return;
                                                        }

                                                        setPendingTx(true);

                                                        try {
                                                            const args = [
                                                                new BigNumber(parseFloat(withdrawSum))
                                                                    .multipliedBy(BIG_TEN.pow(UZD_DECIMALS))
                                                                    .dp(0, BigNumber.ROUND_DOWN)
                                                                    .toString(),
                                                                true,
                                                                account,
                                                            ];

                                                            if (withdrawAll) {
                                                                args[0] = balance.toString();
                                                            }

                                                            log(`[Earn] Withdraw. Args: [${args.join(',')}]`);

                                                            const txHash = await wallet.data.writeContract({
                                                                address,
                                                                chain: chain,
                                                                abi: earnPoolABI,
                                                                functionName: 'withdraw',
                                                                args,
                                                                account,
                                                            });

                                                            setTransactionId(txHash);

                                                            waitForTransaction({ hash: txHash }).then(() => {
                                                                setPendingTx(false);
                                                            });
                                                        } catch (error: any) {
                                                            setTransactionError(error.message);
                                                            alert(error.message);
                                                            log(`❗️ Error while withdraw: ${error.message}`);
                                                            setPendingTx(false);
                                                        }
                                                    }}
                                                >
                                                    Withdraw
                                                </button>
                                                {pendingTx && <Preloader />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
};
