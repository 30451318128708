import { createPublicClient, createWalletClient, custom, http } from 'viem';
import { mainnet } from 'viem/chains';
import { base } from './components/NetworkSelector/NetworkSelector';

// export const infuraRpcTransportUrl = `https://lb.drpc.org/ogrpc?network=ethereum&dkey=AvTrpkqK10shjlNk2y7YOgQAeihbH00R766FtigucSjy`;
export const infuraRpcTransportUrl = 'https://eth-mainnet.g.alchemy.com/v2/b7b-NDa8uY1HISXI79q2I6FgipeNi8jk';
export const infuraSepoliaRpcTransportUrl = `https://eth-sepolia-public.unifra.io`;
export const alchemyBaseTransportUrl = 'https://base-mainnet.g.alchemy.com/v2/b7b-NDa8uY1HISXI79q2I6FgipeNi8jk';

const chainId = window.hasOwnProperty('ethereum')
    ? parseInt(window.ethereum.chainId, 16)
    : 1;

let transport = http(infuraRpcTransportUrl);

if (chainId === base.id) {
    transport = http(alchemyBaseTransportUrl);
}

export const publicClient = createPublicClient({
    chain: mainnet,
    transport: transport,
});

export const walletClient = createWalletClient({
    chain: mainnet,
    transport: window.ethereum ? custom(window.ethereum) : transport,
});
