import { Address, mainnet, useAccount, useWalletClient } from 'wagmi';
import abi from '../actions/abi/aps.json';
import { NULL_ADDRESS } from '../utils/formatbalance';

const useHarvest = (apsAddress: Address) => {
    const { address: account } = useAccount();
    const wallet = useWalletClient();

    // APS deposit
    async function harvest() {
        // @ts-ignore
        return await wallet.data.writeContract({
            address: apsAddress,
            chain: mainnet,
            abi,
            functionName: 'autoCompoundAll',
            account: account || NULL_ADDRESS,
        });
    }

    return {
        harvest: harvest,
    };
};

export default useHarvest;
