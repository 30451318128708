import { useState, useEffect } from 'react';

export const ETHERSCAN_API_KEY = 'UPPVCS8VTCCNT3T83BZ8H6YRE9WVDY6W2P';

export const useGasPrice = () => {
    const [gasPrice, setGasPrice] = useState('');

    useEffect(() => {
        fetch(`https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${ETHERSCAN_API_KEY}`)
            .then((response) => response.json())
            .then((data) => {
                setGasPrice(Number(data.result.ProposeGasPrice).toLocaleString('en', { maximumFractionDigits: 0 }));
            }).catch((error) => {
                setGasPrice('n/a');
            });
    }, []);

    return gasPrice;
};
