import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { BIG_ZERO } from '../utils/formatbalance';
import { Address, useNetwork } from 'wagmi';
import { getChainClient, getZunStakingAddress } from '../utils/zunami';
import stakingABI from '../actions/abi/zun/ZUNStakingRewardDistributor.json';

const useVotes = (address: Address) => {
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const [votes, setVotes] = useState<BigNumber>(BIG_ZERO);

    useEffect(() => {
        const fetchVotes = async () => {
            let result: BigNumber = BIG_ZERO;

            if (!address) {
                return;
            }

            // @ts-ignore
            result = await getChainClient(chainId).readContract({
                address: getZunStakingAddress(chainId),
                abi: stakingABI,
                args: [address],
                functionName: 'getVotes',
            });

            setVotes(result);
        };

        fetchVotes();
    }, [chainId, address]);

    return votes;
};

export default useVotes;
