import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import { BIG_TEN, BIG_ZERO, NULL_ADDRESS } from '../utils/formatbalance';
import { Address, useNetwork } from 'wagmi';
import { getChainClient, getZunStakingAddress } from '../utils/zunami';
import useAccount from './useAccountOverride';
import stakingABI from '../actions/abi/zun/ZUNStakingRewardDistributor.json';

const useDelegates = () => {
    const { chain } = useNetwork();
    const chainId = chain ? chain.id : undefined;
    const { address } = useAccount();
    const [delegates, setDelegates] = useState<Address>(NULL_ADDRESS);

    useEffect(() => {
        const fetchDelegates = async () => {
            let result: Address = NULL_ADDRESS;

            if (!address) {
                return;
            }

            // @ts-ignore
            result = await getChainClient(chainId).readContract({
                address: getZunStakingAddress(chainId),
                abi: stakingABI,
                args: [address],
                functionName: 'delegates',
            });

            console.log(`[DAO] Delegated to ${result}`);
            setDelegates(result);
        };

        fetchDelegates();
    }, [chainId, address]);

    return delegates;
};

export default useDelegates;
